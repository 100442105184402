import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import product1 from "./assets/images/noburestaurent.png";
import product2 from "./assets/images/noburestaurent.png";
import product3 from "./assets/images/noburestaurent.png";

const ProductsPage = () => {
  const navigate = useNavigate(); // Initialize navigation
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const productsData = [
    {
      id: 1,
      image: product1,
      name: "Premium Tattoo Ink Set",
      description: "High-quality, vibrant ink set for professional tattoo artists.",
      fullDescription: "Our Premium Tattoo Ink Set is meticulously crafted for professional tattoo artists who demand the highest quality. Each color is developed with advanced pigment technology, ensuring vibrant, long-lasting results. The set includes 12 unique colors, carefully selected to provide a comprehensive palette for any artistic vision.",
      price: "$129.99",
      features: [
        "12 Unique Professional Colors",
        "Long-lasting Pigmentation",
        "Hypoallergenic Formula",
        "Smooth Application",
        "Vegan and Cruelty-Free"
      ],
      technicalSpecs: {
        "Ink Volume": "15ml per color",
        "Pigment Concentration": "High",
        "Color Stability": "Up to 5+ Years",
        "Skin Compatibility": "Dermatologically Tested"
      },
      certifications: [
        "FDA Approved",
        "Vegan Certified",
        "Professional Grade"
      ]
    },
    {
      id: 2,
      image: product2,
      name: "Artist Pro Tattoo Machine",
      description: "Precision-engineered rotary tattoo machine for smooth lines.",
      fullDescription: "The Artist Pro Tattoo Machine represents the pinnacle of tattoo engineering. Designed with input from top artists worldwide, this machine offers unparalleled precision, comfort, and performance. Its ergonomic design and advanced motor technology ensure consistent line work and minimal skin trauma.",
      price: "$349.99",
      features: [
        "Lightweight Ergonomic Design",
        "Brushless DC Motor",
        "Adjustable Stroke Length",
        "Quiet Operation",
        "Compatible with All Needle Configurations"
      ],
      technicalSpecs: {
        "Weight": "125 grams",
        "Motor Type": "Brushless DC",
        "Voltage Range": "4.5-7.5V",
        "Noise Level": "< 55 dB",
        "Battery Life": "8+ Hours Continuous Use"
      },
      certifications: [
        "Professional Tattoo Equipment Standard",
        "Precision Engineering Certified",
        "Ergonomic Design Award"
      ]
    },
    {
      id: 3,
      image: product3,
      name: "Healing Tattoo Care Kit",
      description: "Complete aftercare kit for optimal tattoo healing and maintenance.",
      fullDescription: "Our Healing Tattoo Care Kit is a comprehensive solution for tattoo aftercare. Developed by skincare experts and tattoo professionals, this kit provides everything needed to ensure proper healing, vibrant color preservation, and long-term tattoo health. It includes specialized products for each stage of the healing process.",
      price: "$49.99",
      features: [
        "Multi-Stage Healing Process",
        "Natural Ingredients",
        "Anti-Bacterial Protection",
        "Moisturizing Formula",
        "Prevents Color Fading"
      ],
      technicalSpecs: {
        "Kit Contents": "Cleanser, Healing Balm, Moisturizer",
        "Ingredients": "100% Natural Extracts",
        "Application": "Multiple Times Daily",
        "Skin Type": "All Skin Types"
      },
      certifications: [
        "Dermatologist Tested",
        "Organic Ingredients",
        "Hypoallergenic"
      ]
    }
  ];

  const ProductStats = () => (
    <div className="bg-[#527DE6] rounded-2xl p-8 mb-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">500+</h3>
          <p className="text-xl">Satisfied Professional Clients</p>
        </div>
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">3</h3>
          <p className="text-xl">Award-Winning Product Lines</p>
        </div>
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">100%</h3>
          <p className="text-xl">Quality Guaranteed</p>
        </div>
      </div>
    </div>
  );

  const ProductMissionStatement = () => (
    <div className="bg-[#527DE6] rounded-2xl p-8 mb-12 text-white text-center">
      <h2 className="text-3xl font-bold mb-4">Our Product Philosophy</h2>
      <p className="text-xl max-w-4xl mx-auto">
        We craft professional-grade tattoo equipment and care products that empower artists to create extraordinary art while ensuring the highest standards of quality, safety, and performance.
      </p>
    </div>
  );

  const ProductCard = ({ product, onSelect }) => (
    <div 
      className="bg-[#0D182E] rounded-xl p-6 space-y-4 transform transition-all duration-300 hover:scale-105 cursor-pointer"
      onClick={() => onSelect(product)}
    >
      <div className="h-64 overflow-hidden rounded-lg">
        <img 
          src={product.image} 
          alt={product.name} 
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      <div className="text-white text-center">
        <h3 className="text-2xl font-bold mb-2">{product.name}</h3>
        <p className="text-[#65D4B0] mb-2">{product.price}</p>
        <p className="text-gray-300 mb-4">{product.description}</p>
        <button className="bg-[#B7BDCA] text-black px-6 py-2 rounded-lg hover:bg-opacity-80 transition-all">
          View Details
        </button>
      </div>
    </div>
  );

  const ProductDetailModal = ({ product, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center overflow-y-auto py-16 px-4">
      <div className="bg-[#0D182E] rounded-2xl max-w-4xl w-full relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white text-3xl hover:text-[#65D4B0] z-10"
        >
          &times;
        </button>
        <div className="flex flex-col md:flex-row p-8">
          <div className="w-full md:w-1/3 mb-6 md:mb-0 md:pr-8">
            <img 
              src={product.image} 
              alt={product.name} 
              className="w-full h-auto rounded-xl object-cover"
            />
          </div>
          <div className="w-full md:w-2/3 text-white">
            <h2 className="text-3xl font-bold mb-2">{product.name}</h2>
            <p className="text-[#65D4B0] text-xl mb-4">{product.price}</p>
            <p className="text-gray-300 mb-6">{product.fullDescription}</p>
            
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Key Features</h3>
              <div className="flex flex-wrap gap-2">
                {product.features.map((feature, index) => (
                  <span 
                    key={index} 
                    className="bg-[#65D4B0] bg-opacity-20 text-[#65D4B0] px-3 py-1 rounded-full text-sm"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
            
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Technical Specifications</h3>
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(product.technicalSpecs).map(([key, value]) => (
                  <div key={key} className="bg-[#527DE6] bg-opacity-20 p-2 rounded">
                    <span className="text-gray-300 block">{key}</span>
                    <span className="text-white font-semibold">{value}</span>
                  </div>
                ))}
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-3">Certifications</h3>
              <div className="flex space-x-4">
                {product.certifications.map((cert, index) => (
                  <span 
                    key={index}
                    className="bg-[#B7BDCA] text-black px-3 py-1 rounded-full text-sm"
                  >
                    {cert}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="w-full bg-[#4D6F8F] p-3 space-y-8" style={{fontFamily: 'GeneralSansRegular'}}>
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8 mt-16 flex justify-between items-center">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            OUR PRODUCTS
          </h1>
        </div>
        <div className="pr-6">
          <button 
            onClick={() => navigate('/BusinessDashboard/AddProducts')} 
            className="bg-[#B7BDCA] text-[#0D182E] px-6 py-2 rounded-lg hover:bg-opacity-80 transition-all"
          >
            Add Product
          </button>
        </div>
      </div>

      <ProductStats />

      <div className="w-full bg-[#0D182E] py-12 px-8 rounded-2xl border-8 border-[#B7BDCA]">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl text-white font-bold mb-8 text-center">
            Professional Tattoo Equipment
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {productsData.map((product) => (
              <ProductCard 
                key={product.id} 
                product={product} 
                onSelect={setSelectedProduct} 
              />
            ))}
          </div>
        </div>
      </div>

      {selectedProduct && (
        <ProductDetailModal 
          product={selectedProduct} 
          onClose={() => setSelectedProduct(null)} 
        />
      )}
    </div>
  );
};

export default ProductsPage;