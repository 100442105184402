import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';
import { Building, User } from 'lucide-react';

const RoleSelectionPage = () => {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('');

  const handleContinue = () => {
    if (selectedRole === 'business') {
      navigate('/CreateAccountB');
    } else if (selectedRole === 'customer') {
      navigate('/CreateAccountU');
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8">
        <div className="space-y-8 font-['GeneralSansRegular']">
        <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
            </div>
        </div>
      </div>

      {/* Role Selection Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Continue as</h2>
        
        <div className="space-y-4">
          <button
            className={`w-full flex items-center justify-between bg-white/5 border border-white/10 rounded-lg px-4 py-3 
              transition-all duration-300 hover:bg-white/10 
              ${selectedRole === 'business' 
                ? 'ring-2 ring-white/30 border-transparent' 
                : 'border-white/10'}`}
            onClick={() => setSelectedRole('business')}
          >
            <div className="flex items-center gap-4">
              <div className="p-2 bg-white/10 rounded-full">
                <Building className="text-white" />
              </div>
              <div className="text-left">
                <h4 className="text-white font-['GeneralSansSemibold']">Business/Company</h4>
                <p className="text-sm text-white/70 font-['GeneralSansRegular']">Continue as a Business/Company Account</p>
              </div>
            </div>
            {selectedRole === 'business' && (
              <div className="text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M19.916 4.616a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </button>

          <button
            className={`w-full flex items-center justify-between bg-white/5 border border-white/10 rounded-lg px-4 py-3 
              transition-all duration-300 hover:bg-white/10 
              ${selectedRole === 'customer' 
                ? 'ring-2 ring-white/30 border-transparent' 
                : 'border-white/10'}`}
            onClick={() => setSelectedRole('customer')}
          >
            <div className="flex items-center gap-4">
              <div className="p-2 bg-white/10 rounded-full">
                <User className="text-white" />
              </div>
              <div className="text-left">
                <h4 className="text-white font-['GeneralSansSemibold']">Customer/Consumer</h4>
                <p className="text-sm text-white/70 font-['GeneralSansRegular']">Continue as a Customer/Consumer Account</p>
              </div>
            </div>
            {selectedRole === 'customer' && (
              <div className="text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    fillRule="evenodd"
                    d="M19.916 4.616a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            )}
          </button>
        </div>

        <button
          className={`w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
            py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
            transition-all duration-300 shadow-lg hover:shadow-xl 
            hover:scale-[1.02] mt-6
            ${!selectedRole && 'opacity-70 cursor-not-allowed'}`}
          disabled={!selectedRole}
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>

      <div className="mt-8 text-center">
        <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
          Already have an account?
        </span>
        <button 
          onClick={() => navigate('/SignIn')}
          className="text-white font-['GeneralSansMedium'] hover:underline"
        >
          Sign In
        </button>
      </div>
    </div>
  );
};

export default RoleSelectionPage;