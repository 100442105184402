import React from "react";
import { useNavigate } from "react-router-dom";

const BPrivacyPolicy = () => {
  return (
    <div className="min-h-screen  pt-20 px-4 sm:px-6 lg:px-8">
      {/* Full-width rounded section */}
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            PRIVACY POLICY
          </h1>
        </div>
      </div>

      <div className="max-w-full bg-[#0D182E] rounded-lg shadow-md p-6 sm:p-8 ">
        <h1 className="text-3xl font-['GeneralSansBold'] text-white mb-6 border-b-2  pb-4"         style={{
          borderImage: 'linear-gradient(105.41deg, #3DE9DC -17.48%, #889EE7 35.37%, #DC3DF7 99.28%)',
          borderImageSlice: 1,
        }}>
          Privacy Policy
        </h1>

        <section className="mb-6">
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam 
            auctor, nunc eget ultricies tincidunt, velit velit bibendum velit, 
            vel bibendum sapien nunc vel lectus. Fusce euismod, nunc sit amet 
            aliquam lacinia, nisi enim lobortis enim, vel lacinia nunc enim 
            eget nunc.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            1. Information We Collect
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do 
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim 
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut 
            aliquip ex ea commodo consequat.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            2. How We Use Your Information
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque 
            habitant morbi tristique senectus et netus et malesuada fames ac 
            turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies 
            eget, tempor sit amet, ante.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            3. Data Protection
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec 
            euismod, nisl eget ultricies aliquam, augue nisl tincidunt nisl, 
            eget aliquam nisl nisl sit amet nisl. Sed euismod, nisl eget 
            ultricies aliquam, augue nisl tincidunt nisl.
          </p>
        </section>

        <section className="">
          <h2 className="text-2xl font-['GeneralSansMedium'] text-white mb-4">
            4. Contact Us
          </h2>
          <p className="text-white font-['GeneralSansRegular'] leading-relaxed">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin 
            tristique in tortor et dignissim. Sed vel lorem et justo laoreet 
            bibendum. Donec euismod, nisl eget ultricies aliquam.
          </p>
        </section>
      </div>
    </div>
  );
};

export default BPrivacyPolicy;