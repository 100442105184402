import React from 'react';
import ProfileImage from './assets/images/profile.png';
import ReviewIcon from './assets/images/review.png';

const Referrals = () => {
  const customerData = [
    { 
      backgroundColor: 'bg-[#527DE6]', 
      name: 'Kat Larsson', 
      email: 'kat@contoso.org', 
      phone: '(469) 532 2382' 
    },
    { 
      backgroundColor: 'bg-[#65D4B0]', 
      name: 'Kat Larsson', 
      email: 'kat@contoso.org', 
      phone: '(469) 532 2382' 
    },
    { 
      backgroundColor: 'bg-[#DB005F]', 
      name: 'Kat Larsson', 
      email: 'kat@contoso.org', 
      phone: '(469) 532 2382' 
    },
    { 
        backgroundColor: 'bg-[#FE8705]', 
        name: 'Kat Larsson', 
        email: 'kat@contoso.org', 
        phone: '(469) 532 2382' 
      },
      { 
        backgroundColor: 'bg-[#DC3DF7]', 
        name: 'Kat Larsson', 
        email: 'kat@contoso.org', 
        phone: '(469) 532 2382' 
      },
      { 
        backgroundColor: 'bg-[#527DE6]', 
        name: 'Kat Larsson', 
        email: 'kat@contoso.org', 
        phone: '(469) 532 2382' 
      },
  ];

  return (
    <div className="my-20 mx-10">
    <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
             REFERRALS
          </h1>
        </div>
      </div>
      <div className="bg-[#0D182E] rounded-lg p-10 mt-5">
        {customerData.map((customer, index) => (
          <div key={index} className="flex items-center mb-4">
            {/* Diamond Container */}
            <div className={`w-[45px] h-[45px] transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${customer.backgroundColor} mr-2.5`}>
              <div className="w-full h-full -rotate-45 overflow-hidden">
                <img 
                  src={ProfileImage} 
                  alt="Profile" 
                  className="w-[120%] h-[125%] object-contain absolute -left-[10%]" 
                />
              </div>
            </div>

            {/* Customer Details */}
            <div className="ml-4 flex-1">
              <p className="text-base text-white font-['GeneralSansMedium']">{customer.name}</p>
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.email}</p>
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{customer.phone}</p>
            </div>

            {/* Review Link */}
            <div className="flex justify-end">
              <div className="flex items-center border border-white rounded-xl px-3 py-2 bg-transparent">
                <img 
                  src={ReviewIcon} 
                  alt="Review" 
                  className="w-4 h-4 mr-2" 
                />
                <span className="text-base text-white font-['GeneralSansMedium']">Referral Link</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Referrals;