import React from 'react';
import { Mail, MapPin, User, ArrowLeft, ArrowRight } from 'lucide-react';
import paypal from "./assets/images/paypal-icon.png";
import userProfileImage from "./assets/images/profile.png"; // You'll want to replace this with an actual user profile image
import logo from "./assets/images/loogfinal.png";
import { useNavigate } from 'react-router-dom';

const UserReviewConfirm = () => {
  const navigate = useNavigate();
  const userData = {
    email: "jane.smith@example.com",
    firstName: "Jane",
    lastName: "Smith",
    address: "456 Residential Street, Melbourne VIC 3000",
    paypalEmail: "jane.smith@paypal.com",
    profilePicture: userProfileImage
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
        <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
            </div>
        </div>
      </div>

      {/* Review and Confirm Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-4xl border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">
          Review And Confirm
        </h2>

        <div className="space-y-6">
          {/* Personal Profile Section */}
          <div className="bg-white/5 border border-white/10 rounded-lg p-6">
            <h3 className="text-white text-xl font-['GeneralSansBold'] mb-4">
              Personal Profile
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Profile Picture */}
              <div className="flex justify-center items-center mb-4 md:mb-0">
                <div className=" w-32 h-32 rotate-45 overflow-hidden bg-[#527DE6] rounded-xl border-black border-2">
                  <img 
                    src={userData.profilePicture} 
                    alt="Profile"
                    className="-rotate-45  object-contain w-full h-full mt-5 ml-5"
                  />
                </div>
              </div>
              
              {/* Personal Details */}
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <User className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300 font-['GeneralSansRegular']">First Name</p>
                    <p className="text-white font-['GeneralSansRegular']">{userData.firstName}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <User className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300 font-['GeneralSansRegular']">Last Name</p>
                    <p className="text-white font-['GeneralSansRegular']">{userData.lastName}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <Mail className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300 font-['GeneralSansRegular']">Email</p>
                    <p className="text-white font-['GeneralSansRegular']">{userData.email}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <MapPin className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300 font-['GeneralSansRegular']">Address</p>
                    <p className="text-white font-['GeneralSansRegular']">{userData.address}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PayPal Section */}
          <div className="bg-white/5 border border-white/10 rounded-lg p-6">
            <div className="flex items-center space-x-4">
              <img src={paypal} alt="PayPal" className="h-12 w-16 object-contain" />
              <div>
                <p className="text-sm text-gray-300 font-['GeneralSansRegular']">PayPal Email</p>
                <p className="text-white font-['GeneralSansRegular']">{userData.paypalEmail}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-6 pt-4 border-t border-white/20">
          <button
            type="button"
            className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
            font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-300"
            onClick={() => navigate("/UserDetails")}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <button
            type="button"
            className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
            text-white rounded-lg font-['GeneralSansBold'] hover:opacity-90 
            transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-[1.02]"
            onClick={() => navigate("/UserDashboard")}
          >
            Continue
            <ArrowRight className="w-4 h-4 ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserReviewConfirm;