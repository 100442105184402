import React, { useState } from 'react';
import logo2 from "./assets/images/sheild.png";
import userProfileBg from "./assets/images/imgspell.png";
import userAvatar from "./assets/images/happyman.png";
import mapicon from "./assets/images/Layer 2.png"
import img1 from "./assets/images/cafe.png"
import img2 from './assets/images/imagestatic1.png'
import img3 from "./assets/images/restfav.png"
import profile from "./assets/images/profile.png";
import logo from "./assets/images/loogfinal.png";
import logoauth from "./assets/images/logoauthentic.png"
import placesToVisitIcon from "./assets/images/tabicon1.png";
import myShopIcon from "./assets/images/tabicon2.png";
import favoritesIcon from "./assets/images/heart.png";
import reviewsIcon from "./assets/images/Existing Review.png"; // Assuming you'll add a reviews icon

const UserProfile = () => {
  const [isFollowing, setIsFollowing] = useState(false);
  const [activeTab, setActiveTab] = useState('placesToVisit');
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  const progressData = [
    { label: 'Reviews', value: 75 },
    { label: 'Referrals', value: 60 },
    { label: 'Followers', value: 90 },
    { label: 'Level', value: 60 },
    { label: 'Brand Ambassador', value: 50 },
    { label: 'Popularity', value: 70 },
  ];

  const placesToVisitData = [
    { id: 1, name: 'Beach Resort', image: img1 },
    { id: 2, name: 'Mountain Cabin', image: img1  },
    { id: 3, name: 'City Apartment', image: img1  },
    { id: 4, name: 'Beach Resort', image: img1 },
    { id: 5, name: 'Mountain Cabin', image: img1  },
    { id: 6, name: 'City Apartment', image: img1  },
    { id: 7, name: 'Beach Resort', image: img1 },
    { id: 8, name: 'Mountain Cabin', image: img1  },
    { id: 9, name: 'City Apartment', image: img1  },
  ];

  const myShopData = [
    { id: 1, name: 'Product 1', image: img2 },
    { id: 2, name: 'Product 2', image: img2 },
    { id: 3, name: 'Product 3', image: img2 }
  ];

  const favoritesData = [
    { id: 1, name: 'Favorite Place 1', image: img3 },
    { id: 2, name: 'Favorite Place 2', image: img3 },
    { id: 3, name: 'Favorite Place 3', image: img3 }
  ];
  

  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: "Quality", value: "9", percentage: 90 },
        { label: "Service", value: "10", percentage: 100 },
        { label: "Cleanliness", value: "9.5", percentage: 95 },
        { label: "Value", value: "8.5", percentage: 85 },
        { label: "Communication", value: "9.8", percentage: 98 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: "Quality", value: "9", percentage: 90 },
        { label: "Service", value: "9.5", percentage: 95 },
        { label: "Cleanliness", value: "9", percentage: 90 },
        { label: "Value", value: "8", percentage: 80 },
        { label: "Communication", value: "9.5", percentage: 95 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: "Quality", value: "8.5", percentage: 85 },
        { label: "Service", value: "9", percentage: 90 },
        { label: "Cleanliness", value: "9", percentage: 90 },
        { label: "Value", value: "8.5", percentage: 85 },
        { label: "Communication", value: "9", percentage: 90 }
      ]
    }
  ];



  const PerformanceBar = ({ label, value, percentage }) => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
      <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
      </div>
      <div className="flex items-center space-x-4 w-full sm:w-3/4">
        <div className="flex-grow bg-gray-700 h-3 rounded-full">
          <div 
            className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
          {value}/10
        </span>
      </div>
    </div>
  );
  return (
    <div className="w-full p-8 space-y-8" style={{fontFamily: 'GeneralSansRegular'}}>
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8 mt-16">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            Profile
          </h1>
        </div>
      </div>

      {/* Profile Container with Reduced Opacity Background */}
      <div className="relative w-full h-[500px] rounded-2xl overflow-hidden border-4 border-black">
        <div 
          className="absolute inset-0 bg-[#527DE6]" 
          style={{
            backgroundImage: `url(${userProfileBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.3 // Reduced opacity for imgspell background
          }}
        />

        {/* Rest of the profile container remains the same */}
        <div className="absolute top-4 right-4">
          <img 
            src={logo2} 
            alt="Company Logo" 
            className="h-12 w-auto"
          />
        </div>

        <div className="absolute bottom-1/2 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <img 
            src={userAvatar} 
            alt="User Avatar" 
            className="w-full h-full object-contain mt-20"
          />
        </div>

        <div className="absolute bottom-8 left-8">
          <h1 
            className="text-3xl text-white font-[VarinoRegular]"
            style={{ fontFamily: 'VarinoRegular' }}
          >
            John Doe
          </h1>
        </div>

        <div className="absolute bottom-5 right-8">
          <button 
            onClick={() => setIsFollowing(!isFollowing)}
            className={`px-3 py-2 rounded-lg transition-colors duration-300 ${
              isFollowing 
                ? 'bg-[#B7BDCA] text-[#0D182E]' 
                : 'bg-[#0D182E] text-white'
            }`}
            style={{ fontFamily: 'GeneralSansMedium' }}
          >
            {isFollowing ? 'Following' : 'Follow Me'}
          </button>
        </div>
      </div>

      {/* Progress Bars Section */}
      <div className="relative w-full  rounded-2xl p-6 flex items-center">
        <div className="w-2/3">
          {progressData.map((item, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between mb-2">
                <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.label}</span>
                <span className="font-[GeneralSansMedium] text-[#0D182E]">{item.value}</span>
              </div>
              <div className="w-full bg-white rounded-full h-3">
                <div 
                  className="bg-[#DC3DF7] h-3 rounded-full" 
                  style={{width: `${item.value}%`}}
                ></div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-1/3 pl-6">
          <img 
            src={mapicon} 
            alt="Large Profile" 
            className="w-full h-auto"
          />
        </div>
      </div>

      {/* Tabs Section */}
      <div className="w-full rounded-2xl p-6">
        <div className="flex justify-center space-x-8 mb-6">
          {[
            { 
              key: 'placesToVisit', 
              icon: placesToVisitIcon, 
              label: 'Places To Visit' 
            },
            { 
              key: 'myShop', 
              icon: myShopIcon, 
              label: 'My Shop' 
            }
          ].map((tab) => (
            <div 
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
              className={`
                flex flex-col items-center cursor-pointer 
                transform transition-all duration-300 
                group relative pb-2 font-[VarinoRegular]
                ${activeTab === tab.key 
                  ? 'text-[#0D182E]' 
                  : 'text-white hover:text-[#0D182E]'}
              `}
            >
              <div className={`
                w-16 h-16 rounded-full flex items-center justify-center mb-2
                transition-all duration-300
                ${activeTab === tab.key 
                  ? 'bg-[#527DE6]/10' 
                  : 'group-hover:bg-[#527DE6]/5'}
              `}>
                <img 
                  src={tab.icon} 
                  alt={tab.label} 
                  className={`
                    w-8 h-8 
                    transition-transform duration-300
                    ${activeTab === tab.key 
                      ? 'scale-110' 
                      : 'group-hover:scale-105'}
                  `}
                />
              </div>
              <span className="text-sm font-medium">{tab.label}</span>
              
              {/* Active indicator */}
              <div className={`
                absolute bottom-0 h-1 w-full rounded-full 
                transition-all duration-300
                ${activeTab === tab.key 
                  ? 'bg-[#0D182E]' 
                  : 'bg-transparent'}
              `}></div>
            </div>
          ))}
        </div>

        {/* Carousel for Active Tab */}
       <div className="flex overflow-x-auto space-x-4 pb-4 scrollbar-hide">
  {(activeTab === 'placesToVisit' ? placesToVisitData : myShopData).map((item) => (
    <div key={item.id} className="flex-shrink-0 w-64 rounded-lg shadow-md">
      <img 
        src={item.image} 
        alt={item.name} 
        className="w-full h-48 object-cover rounded-t-lg"
      />
      <div className="p-4 bg-white rounded-md">
        <h3 className="text-lg font-semibold text-[#0D182E]">{item.name}</h3>
      </div>
    </div>
  ))}
</div>
<style jsx global>{`
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`}</style>
      </div>

      {/* Favorites Section */}
      <div className="w-full  rounded-2xl p-6">
        <div className="flex items-center mb-4">
          <img src={favoritesIcon} alt="Favorites" className="w-8 h-8 mr-4" />
          <h2 className="text-2xl font-semibold text-[#0D182E] font-[VarinoRegular]">Favorites</h2>
        </div>

        <div className="flex overflow-x-auto space-x-4 pb-4">
          {favoritesData.map((item) => (
            <div key={item.id} className="flex-shrink-0 w-64 bg-white rounded-lg shadow-md text-[#0D182E]">
              <img 
                src={item.image} 
                alt={item.name} 
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold">{item.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

        {/* Reviews Section - Similar to Favorites Section */}
        <section className="mb-8 bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex items-center mb-4">
          <img src={reviewsIcon} alt="Reviews" className="w-8 h-8 mr-4" />
          <h2 className="text-xl sm:text-2xl  font-[VarinoRegular] text-white">Existing Reviews</h2>
        </div>

        {/* Reviews Carousel Container */}
        <div className="relative w-full overflow-hidden">
          {/* Navigation Buttons */}
          {currentReviewIndex > 0 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex - 1)}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &lt;
            </button>
          )}
          {currentReviewIndex < reviewsData.length - 1 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex + 1)}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &gt;
            </button>
          )}

          {/* Reviews Content */}
          <div 
            className="w-full transition-transform duration-300 ease-in-out"
            style={{ 
              transform: `translateX(-${currentReviewIndex * 100}%)`,
            }}
          >
            <div className="flex">
              {reviewsData.map((review) => (
                <div 
                  key={review.id} 
                  className="w-full flex-shrink-0 bg-[#1a2942] rounded-lg shadow-md p-6"
                >
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
                    <div className="flex items-start space-x-4 mb-2 sm:mb-0">
                      <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
                        <img
                          src={profile}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div>
                        <h3 className="font-[VarinoRegular] text-white text-lg">{review.name}</h3>
                        <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">
                          {review.subtitle}
                        </p>
                      </div>
                    </div>
                    <span className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</span>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6 space-y-4 sm:space-y-0">
                    <div className="flex space-x-2">
                      {[1, 2, 3, 4, 5].map((item) => (
                        <img
                          key={item}
                          src={logo}
                          alt="Rating"
                          className="w-6 sm:w-8 h-4 sm:h-6"
                        />
                      ))}
                    </div>
                    <img
                      src={logoauth}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10"
                    />
                  </div>

                  <div className="mb-4">
                    <h4 className="text-white font-['GeneralSansBold'] text-base sm:text-lg mb-2">
                      Customer Experience: {review.customerExperience}
                    </h4>
                  </div>

                  <p className="font-['GeneralSansRegular'] text-gray-300 mb-6 text-sm sm:text-base">
                    {review.review}
                  </p>

                  <div className="space-y-2">
                    {review.performanceBars.map((bar, index) => (
                      <PerformanceBar 
                        key={index} 
                        label={bar.label} 
                        value={bar.value} 
                        percentage={bar.percentage} 
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center mt-4 space-x-2">
            {reviewsData.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentReviewIndex(index)}
                className={`
                  w-3 h-3 rounded-full 
                  ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}
                `}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserProfile;