import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import logo from "./assets/images/loogfinal.png"; 
import profilePhoto from "./assets/images/profile.png"; 
import { useNavigate } from 'react-router-dom';

const QRCodePage = () => {
  const userData = {
    name: "John Doe",
    email: "johndoe@example.com",
    profilePicture: profilePhoto,
    qrCodeData: "https://reviewpay.com/profile/johndoe"
  };
  const navigate=useNavigate();

  const handleShareQRCode = () => {
    navigator.clipboard.writeText(userData.qrCodeData)
      .then(() => alert('QR Code link copied to clipboard!'));
  };
  const handleCantScanQR = () => {
    // Make sure this matches exactly with your route definition
    navigate('CantScanqr');  // or whatever the exact route is
  };

  return (
    <div className="min-h-screen  py-10 flex flex-col items-center p-4 pt-32">
      <div className=" w-1/3 max-w-4xl text-center mb-8">
        <h1 className="text-4xl font-[GeneralSansSemibold] text-white mb-4  ">
          Scan the QR code with the authenticator app
        </h1>
      </div>

      <div className="w-full max-w-md">
        <div className="flex flex-col items-center space-y-6">
          <div className="w-80 h-80 bg-[#0D182E] border-4 border-[#DC3DF7] rounded-3xl flex items-center justify-center">
            <QRCode
              id="qr-code-svg"
              value={userData.qrCodeData}
              size={250}
              logoImage={logo}
              logoWidth={65}
              logoHeight={55}
              logoPadding={5}
              logoPaddingStyle='square'
              logoOpacity={1}
              quietZone={8}
              fgColor="#ffffff"
              bgColor="#0D182E"
            />
          </div>

          <p className="text-white text-sm mb-4 font-[GeneralSansRegular] underline" 
          onClick={handleCantScanQR}>
            Cannot scan code?
            
          </p>

          <button 
            onClick={handleShareQRCode}
            className="w-full bg-[#0D182E] text-white px-6 py-3 rounded-xl  transition-colors flex items-center justify-center font-[GeneralSansMedium]"
          >
            Scan QR Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodePage;