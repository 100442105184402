import React from 'react';
import { Bookmark, Users, DollarSign, Eye, ShoppingCart, Clock, Send } from 'lucide-react';
import profile from "./assets/images/profile.png";
import logo from "./assets/images/loogfinal.png";
import logo2 from "./assets/images/logoauthentic.png";

const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-[#0D182E] p-6 rounded-lg">
    <div className="flex items-center gap-4 mb-2">
      <div className="p-3 bg-transparent border-2 rounded-full">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
    </div>
    <p className="text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
  </div>
);

const PerformanceBar = ({ label, value, percentage }) => (
  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
    <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
    </div>
    <div className="flex items-center space-x-4 w-full sm:w-3/4">
      <div className="flex-grow bg-gray-700 h-3 rounded-full">
        <div 
          className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
        {value}/10
      </span>
    </div>
  </div>
);

const CustomerRow = ({ name, email, image }) => (
  <tr className="hover:bg-[#1a2942] flex flex-col sm:table-row mb-4 sm:mb-0">
    <td className="px-4 sm:px-6 py-4 flex items-center">
      <div className="flex items-center space-x-4">
        <div className="w-10 h-10 rounded-full overflow-hidden">
          <img src={image} alt={name} className="w-full h-full object-cover" />
        </div>
        <div>
          <p className="font-['GeneralSansRegular'] text-white">{name}</p>
          <p className="font-['GeneralSansRegular'] text-gray-400 text-sm">{email}</p>
        </div>
      </div>
    </td>
    <td className="px-4 sm:px-6 py-2 sm:py-4 font-['GeneralSansRegular'] text-white">Product Review</td>
    <td className="px-4 sm:px-6 py-2 sm:py-4">
      <span className="px-2 py-1 text-xs font-['GeneralSansMedium'] rounded-full bg-green-900 text-green-100">
        Completed
      </span>
    </td>
    <td className="px-4 sm:px-6 py-2 sm:py-4">
      <button className="w-full sm:w-auto flex items-center justify-center space-x-2 px-4 py-2 border border-white rounded-lg text-white bg-transparent hover:bg-white/10 transition-colors">
        <Send size={16} />
        <span className="font-['GeneralSansMedium']">Contact</span>
      </button>
    </td>
  </tr>
);

const BusinessHome = () => {
  return (
    <>
      {/* Stats Grid */}
      <div className="bg-[#65D4B0] m-6 p-5 mt-20  rounded-xl">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-[#0D182E] mb-2">Analytics Overview</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 p-5 rounded-2xl'>
          <StatCard 
            icon={Bookmark}
            title="Reviews"
            value="85%"
            description="Increase in monthly active users"
          />
          <StatCard 
            icon={Users}
            title="Refferals"
            value="2,345"
            description="Users active in the last 30 days"
          />
          <StatCard 
            icon={DollarSign}
            title="Revenue"
            value="$12,345"
            description="Total revenue this month"
          />
          <StatCard 
            icon={Eye}
            title="Page Visits"
            value="4.8"
            description="Average rating from users"
          />
          <StatCard 
            icon={ShoppingCart}
            title="Whishlist"
            value="1,234"
            description="Total orders this month"
          />
          <StatCard 
            icon={Clock}
            title="Time on App"
            value="78%"
            description="User engagement rate"
          />
        </div>
      </div>

      <section className="mb-8 bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6 m-6">
  <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">Latest Reviews</h2>
  <div className="space-y-6">
    <div className="bg-[#1a2942] rounded-lg p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
        <div className="flex items-start space-x-4 mb-2 sm:mb-0">
          <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
            <img
              src={profile}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <h3 className="font-['VarinoRegular'] text-white text-lg">Floyd Miles</h3>
            <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">
              147 Reviews 23 Referrals - Brand Ambassador
            </p>
          </div>
        </div>
        <span className="font-['GeneralSansRegular'] text-gray-300 text-sm">1 Month Ago</span>
      </div>

      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6 space-y-4 sm:space-y-0">
        <div className="flex space-x-2">
          {[1, 2, 3, 4, 5].map((item) => (
            <img
              key={item}
              src={logo}
              alt="Rating"
              className="w-6 sm:w-8 h-4 sm:h-6"
            />
          ))}
        </div>
        <img
          src={logo2}
          alt="Authentic"
          className="w-28 sm:w-36 h-8 sm:h-10"
        />
      </div>

      <div className="mb-4">
        <h4 className="text-white font-['GeneralSansBold'] text-base sm:text-lg mb-2">
          Customer Experience : 99/100
        </h4>
      </div>

      <p className="font-['GeneralSansRegular'] text-gray-300 mb-6 text-sm sm:text-base">
        First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking.
        However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.
      </p>

      <div className="space-y-2 mb-4">
        <PerformanceBar label="Quality" value="9" percentage={90} />
        <PerformanceBar label="Service" value="10" percentage={100} />
        <PerformanceBar label="Cleanliness" value="9.5" percentage={95} />
        <PerformanceBar label="Value" value="8.5" percentage={85} />
        <PerformanceBar label="Communication" value="9.8" percentage={98} />
      </div>


    </div>
          {/* New Reply Button */}
          <div className="text-left">
        <button 
          className="px-4 py-3 rounded-lg font-['GeneralSansBold'] text-white text-base"
          style={{ backgroundColor: '#B7BDCA',color :"#0D182E" }}
        >
          Reply    
        </button>
      </div>
  </div>
</section>

      {/* Latest Customers Section */}
      <section className="bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6 m-6">
        <h2 className="text-xl sm:text-2xl font-['GeneralSansBold'] text-white mb-4">Latest Customers</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead className="hidden sm:table-header-group">
              <tr className="bg-[#1a2942]">
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Customer</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Review Type</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Status</th>
                <th className="px-6 py-3 text-left text-sm font-['GeneralSansSemibold'] text-white">Action</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              <CustomerRow 
                name="John Doe"
                email="john.doe@example.com"
                image={profile}
              />
              <CustomerRow 
                name="Jane Smith"
                email="jane.smith@example.com"
                image={profile}
              />
              <CustomerRow 
                name="Mike Johnson"
                email="mike.johnson@example.com"
                image={profile}
              />
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default BusinessHome;