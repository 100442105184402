import React, { useState, useEffect } from 'react';
import { Upload, ArrowLeft, ArrowRight, Building2, MapPin, Receipt, OptionIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const SettingsBusinessRegistrationStep = () => {
  const [formData, setFormData] = useState({
    businessName: '',
    address: '',
    category: '',
    subcategory: '',
    abnNumber: '',
    businessImages: []
  });
  const navigate = useNavigate();
  
  const [categories, setCategories] = useState({});
  const [subcategories, setSubcategories] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    fetch('/categories.json')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => {
        console.error('Error loading categories:', error);
        setCategories({
          "Agriculture, Forestry & Fishing": {
            "sub-categories": ["Nursery Production", "Turf Growing"]
          },
          "Mining": {
            "sub-categories": ["Coal Mining", "Oil and Gas Extraction"]
          }
        });
      });
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setFormData({ ...formData, category: selectedCategory, subcategory: '' });
    setSubcategories(selectedCategory && categories[selectedCategory] ? 
      categories[selectedCategory]['sub-categories'] : []);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    handleFileUpload(e.dataTransfer.files);
  };

  const handleFileUpload = (files) => {
    const fileArray = Array.from(files);
    setFormData(prevState => ({
      ...prevState,
      businessImages: [...prevState.businessImages, ...fileArray]
    }));
  };

  const removeImage = (indexToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      businessImages: prevState.businessImages.filter((_, index) => index !== indexToRemove)
    }));
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-24">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
    </div>
    </div>
      {/* Registration Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Business Registration</h2>
        
        <form className="space-y-6 -mb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="relative">
              <label 
                htmlFor="businessName" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Business Name
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <Building2 className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="businessName"
                type="text"
                value={formData.businessName}
                onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                placeholder="Enter your business name"
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative">
              <label 
                htmlFor="abnNumber" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                ABN Number
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <Receipt className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="abnNumber"
                type="text"
                value={formData.abnNumber}
                onChange={(e) => setFormData({ ...formData, abnNumber: e.target.value })}
                placeholder="Enter your ABN number"
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="md:col-span-2 relative">
              <label 
                htmlFor="businessAddress" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
              >
                Business Address
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                <MapPin className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <input
                id="businessAddress"
                type="text"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                placeholder="Enter your business address"
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 placeholder:font-['GeneralSansRegular']"
              />
            </div>

            <div className="relative appearance-none">
              <label 
                htmlFor="businessCategory" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium'] appearance-none"
              >
                Business Category
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none appearance-none z-10">
                <OptionIcon className="h-5 w-5 text-white/50 mt-6 appearance-none" />
              </div>
              <select
                id="businessCategory"
                value={formData.category}
                onChange={handleCategoryChange}
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300 font-['GeneralSansRegular'] appearance-none"
              >
                <option value="" className="text-gray-700 placeholder:font-['GeneralSansRegular'] appearance-none bg-white">Select a category</option>
                {Object.keys(categories).map((category) => (
                  <option key={category} value={category} className="text-gray-700 placeholder:font-['GeneralSansRegular'] appearance-none bg-white">{category}</option>
                ))}
              </select>
            </div>

            <div className="relative appearance-none">
              <label 
                htmlFor="businessSubcategory" 
                className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium'] appearance-none"
              >
                Business Subcategory
              </label>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none appearance-none z-10">
                <OptionIcon className="h-5 w-5 text-white/50 mt-6" />
              </div>
              <select
                id="businessSubcategory"
                value={formData.subcategory}
                onChange={(e) => setFormData({ ...formData, subcategory: e.target.value })}
                disabled={!formData.category}
                required
                className="w-full px-4 py-3 pl-10 bg-[#667d9c] border border-white/10 rounded-lg 
                           text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                           focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                           transition-all duration-300
                           disabled:opacity-50 font-['GeneralSansRegular'] appearance-none"
              >
                <option value="" className="text-gray-700 font-['GeneralSansRegular'] appearance-none bg-white">Select a subcategory</option>
                {subcategories.map((subcategory) => (
                  <option key={subcategory} value={subcategory} className="text-gray-700 placeholder:font-['GeneralSansRegular'] appearance-none bg-white">{subcategory}</option>
                ))}
              </select>
            </div>
          </div>
          {/* File Upload */}
          <div 
            className={`mt-8 border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
              ${isDragging 
                ? 'border-white/50 bg-white/10 scale-[1.02]' 
                : 'border-white/30 hover:border-white/50 bg-white/5'}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              id="fileInput"
              type="file"
              className="hidden"
              accept="image/*"
              multiple
              onChange={(e) => handleFileUpload(e.target.files)}
            />
            <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
              <Upload className="h-8 w-8 text-blue-950" />
            </div>
            <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
              Click to upload or drag and drop
            </p>
            <p className="text-xs text-gray-500">
              SVG, PNG, JPG or GIF (max. 800x400px)
            </p>
          </div>

          {/* Image Preview */}
          {formData.businessImages.length > 0 && (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
              {formData.businessImages.map((image, index) => (
                <div key={index} className="relative">
                  <img 
                    src={URL.createObjectURL(image)} 
                    alt={`Business Image ${index + 1}`} 
                    className="w-full h-24 object-cover rounded-lg"
                  />
                  <button
                    type="button"
                    onClick={() => removeImage(index)}
                    className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                               flex items-center justify-center text-xs hover:bg-red-600"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          )}


        </form>
      </div>


    </div>
  );
};

export default SettingsBusinessRegistrationStep;