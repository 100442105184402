import React, { useState, useEffect } from 'react';
import { 
  Home, Wallet, Bell, Menu, QrCode, Search, X, 
  User, History, Share2, HelpCircle, Settings, 
  BookOpen, FileText, 
  Wallet2Icon,
  BriefcaseBusiness,
  UserCheck2Icon,
  File,
} from 'lucide-react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png";
import profileImage from "./assets/images/happyman.png"; // Add your profile image path here
const DUMMY_PRODUCTS = [
  {
    id: 1,
    name: "Organic Apple Juice",
    brand: "Nature's Best",
    category: "Beverages",
    price: 4.99,
    description: "100% organic pressed apple juice"
  },
  {
    id: 2,
    name: "Whole Wheat Bread",
    brand: "Bakery Fresh",
    category: "Bakery",
    price: 3.49,
    description: "Freshly baked whole wheat bread"
  },
  {
    id: 3,
    name: "Greek Yogurt",
    brand: "Dairy Delight",
    category: "Dairy",
    price: 2.99,
    description: "Creamy Greek yogurt, high in protein"
  },
  {
    id: 4,
    name: "Organic Spinach",
    brand: "Green Farms",
    category: "Produce",
    price: 3.99,
    description: "Fresh organic spinach leaves"
  }
];

const Dashboard = () => {
  const [isNavVisible, setIsNavVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSideSliderOpen, setIsSideSliderOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter products based on search query
    const results = DUMMY_PRODUCTS.filter(product => 
      product.name.toLowerCase().includes(query.toLowerCase()) ||
      product.brand.toLowerCase().includes(query.toLowerCase()) ||
      product.category.toLowerCase().includes(query.toLowerCase())
    );

    setSearchResults(results);
  };

  const handleScanProduct = (productId) => {
    // Placeholder for product scan functionality
    alert(`Scanning product with ID: ${productId}`);
  };

  // Resize Event Listener
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Scroll Event Listener
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsNavVisible(currentScrollY <= lastScrollY || currentScrollY <= 100);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  // Side Slider Menu Item Component
  const SideSliderMenuItem = ({ icon: Icon, text, onClick }) => (
    <div 
      className="flex items-center cursor-pointer group space-x-3 p-3 hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] transition-colors duration-200 hover:rounded-lg"
      onClick={onClick}
    >
      <Icon 
        size={24} 
        className="text-[white] group-hover:text-[white]" 
      />
      <span className="text-base font-['GeneralSansMedium'] text-[white] group-hover:text-[white]">
        {text}
      </span>
    </div>
  );

  // Navigation Item Component
  const NavItem = ({ icon: Icon, text, onClick }) => (
    <div 
      className="flex items-center cursor-pointer group space-x-1 p-2 hover:bg-gradient-to-r hover:from-[#3DE9DC] hover:via-[#889EE7] hover:to-[#DC3DF7] hover:text-white rounded-lg transition-colors duration-200"
      onClick={onClick}
    >
      <Icon 
        size={20} 
        className="text-[white] group-hover:text-white" 
      />
      <span className="text-sm font-['GeneralSansMedium'] text-[white] group-hover:text-white">
        {text}
      </span>
    </div>
  );

  // Navigation handlers
  const handleTermsAndConditions = () => {
    navigate('TermsandConditions');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleQRCodeNavigation = () => {
    navigate('QRCode');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlewalletNavigation = () => {
    navigate('wallet');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlenotificationnavigation = () => {
    navigate('notifications');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlehelp = () => {
    navigate('help');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleFeedback = () => {
    navigate('feedback');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlefaqs = () => {
    navigate('faqs');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlesettings = () => {
    navigate('settings');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleconnectaccount = () => {
    navigate('PaymentBusiness');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleProfilePage = () => {
    navigate('ProfilePage');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handleHomeNavigation = () => {
    navigate('/UserDashboard');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlebusinessdetails = () => {
    navigate('BusinessDetail');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  };
  const handlePrivacyPolicy =()=>{
    navigate('PrivacyPolicy');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }
  const handleimageuserprofile = ()=>{
    navigate('ProfilePage');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }
  const handleInvoice = ()=>{
    navigate('InvoiceHistory');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);
  }

  const handleProfessionalPage =() =>{
    navigate('ProfessionalDetails');
    setIsMobileMenuOpen(false);
    setIsSideSliderOpen(false);

  }


  return (
    <div className="min-h-screen bg-[#4D6F8F] relative overflow-y-auto">
      {/* Side Slider Menu for Web */}
      {!isMobile && isSideSliderOpen && (
        <div className="fixed top-0 right-0 h-full w-64 bg-[#0D182E] shadow-lg z-50 overflow-y-auto">
          <div className="p-4 border-b-4 border-gradient-to-r from-[#3DE9DC] via-[#889EE7] to-[#DC3DF7] flex justify-between items-center">
            <h2 className="text-xl font-['GeneralSansBold'] text-[white]">Menu</h2>
            <button onClick={() => setIsSideSliderOpen(false)}>
              <X size={24} className="text-[white]" />
            </button>
          </div>
          <div className="p-4 space-y-3 text-white">
            <SideSliderMenuItem icon={User} text="Profile" onClick={handleProfilePage} />
            <SideSliderMenuItem icon={History} text="Review History" />
            <SideSliderMenuItem icon={UserCheck2Icon} text="Professional Profile" onClick={handleProfessionalPage} />
            <SideSliderMenuItem icon={QrCode} text="QR Code" onClick={handleQRCodeNavigation} />
            <SideSliderMenuItem icon={Share2} text="Referrals" />
            <SideSliderMenuItem icon={Wallet} text="Wallet" onClick={handlewalletNavigation} />
            <SideSliderMenuItem icon={Settings} text="Settings" onClick={handlesettings} />
            <SideSliderMenuItem icon={HelpCircle} text="Help and Support" onClick={handlehelp} />
            <SideSliderMenuItem icon={BookOpen} text="FAQs" onClick={handlefaqs} />
            <SideSliderMenuItem icon={FileText} text="Feedback" onClick={handleFeedback} />
            <SideSliderMenuItem icon={Wallet2Icon} text="Connect Account" onClick={handleconnectaccount} />
            <SideSliderMenuItem icon={FileText} text="Privacy Policy" onClick={handlePrivacyPolicy}/>
            <SideSliderMenuItem icon={FileText} text="Terms and Conditions" onClick={handleTermsAndConditions} />
            <SideSliderMenuItem icon={File} text="Invoice History" onClick={handleInvoice} />
           
            
          </div>
        </div>
      )}

      {/* Mobile Menu Overlay */}
      {isMobile && isMobileMenuOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 overflow-hidden">
          <div className="fixed right-0 top-0 h-full w-64 bg-[#0D182E] p-4 overflow-y-auto">
            <button onClick={() => setIsMobileMenuOpen(false)} className="absolute top-4 right-4">
              <X size={24} className="text-white" />
            </button>
            <div className="mt-16 space-y-4 pb-20">
              <NavItem icon={User} text="Profile" onClick={handleProfilePage} />
              <NavItem icon={QrCode} text="QR Code" onClick={handleQRCodeNavigation} />
              <NavItem icon={UserCheck2Icon} text="Professional Profile"  />
              <NavItem icon={Bell} text="Notifications" onClick={handlenotificationnavigation} />
              <NavItem icon={History} text="Review History" />
              <NavItem icon={Share2} text="Referrals" />
              <NavItem icon={Wallet} text="Wallet" onClick={handlewalletNavigation} />
              <NavItem icon={Settings} text="Settings" onClick={handlesettings} />
              <NavItem icon={HelpCircle} text="Help and Support" onClick={handlehelp} />
              <NavItem icon={BookOpen} text="FAQs" onClick={handlefaqs} />
              <NavItem icon={FileText} text="Feedback" onClick={handleFeedback} />
              <NavItem icon={FileText} text="Privacy Policy" onClick={handlePrivacyPolicy} />
              <NavItem icon={Wallet2Icon} text="Connect Account" />
              <NavItem icon={BriefcaseBusiness} text="BusinessDetail" onClick={handlebusinessdetails} />
              <NavItem icon={FileText} text="Terms and Conditions" onClick={handleTermsAndConditions} />
              <NavItem icon={File} text="Invoice History" onClick={handleInvoice} />
      
            </div>
          </div>
        </div>
      )}

      {/* Navigation Bar */}
      <nav 
        className={`fixed top-0 w-full bg-[#0D182E] border-b-4 border-[#0D182E] z-40 transition-transform duration-300 
        ${isNavVisible ? 'translate-y-0' : '-translate-y-full  shadow-[0_0_20px_rgba(61,233,220,0),0_0_40px_rgba(136,158,231,0),0_0_60px_rgba(220,61,247,0)]'}
        shadow-[0_0_20px_rgba(61,233,220,0.8),0_0_40px_rgba(136,158,231,0.8),0_0_60px_rgba(220,61,247,0.8)]`}
        style={{
          borderImage: 'linear-gradient(105.41deg, #3DE9DC -17.48%, #889EE7 35.37%, #DC3DF7 99.28%)',
          borderImageSlice: 1,
        }}
      >
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            {/* Logo and Company Name */}
            <div className="flex items-center -ml-4 lg:-ml-20">
              <img src={logo} alt="Company Logo" className="h-8 sm:h-10" />
              <span className="text-xl sm:text-2xl font-['VerminViva'] text-white">User profile</span>
            </div>

            {/* Search Bar - Hidden on mobile */}
            <div className="hidden md:flex relative items-center w-1/3">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearch}
               
                className="w-full pl-10 pr-4 py-2 rounded-lg bg-white/90 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-gradient-to-r focus:from-[#3DE9DC] focus:via-[#889EE7] focus:to-[#DC3DF7] focus:border-transparent font-['GeneralSansRegular'] text-[#0D182E]"
              />
              <Search size={20} className="absolute left-3 text-gray-500" />
              {searchQuery && (
    <div className="absolute top-full left-0 right-0 mt-2 max-w-xl bg-white rounded-lg shadow-lg z-50">
      {searchResults.length > 0 ? (
        searchResults.map(product => (
          <div 
            key={product.id} 
            className="bg-white rounded-lg p-4 mb-2 border-b last:border-b-0 border-gray-200 flex justify-between items-center"
          >
            <div>
              <h3 className="font-bold text-[#0D182E]">{product.name}</h3>
              <p className="text-gray-700">{product.brand} - {product.category}</p>
              <p className="text-gray-700">${product.price}</p>
              <p className="text-sm text-gray-600 mt-2">{product.description}</p>
            </div>
            <button 
              onClick={() => handleScanProduct(product.id)}
              className="bg-gradient-to-r from-[#3DE9DC] via-[#889EE7] to-[#DC3DF7] 
                text-white px-4 py-2 rounded-lg flex items-center space-x-2 
                hover:opacity-90 transition-opacity"
            >
              <QrCode size={20} />
              <span>Scan</span>
            </button>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-700 p-4">No products found</p>
      )}
    </div>
  )}
           </div>

            {/* Desktop Navigation Items */}
            <div className="hidden md:flex items-center space-x-6">
              <NavItem icon={Home} text="Home" onClick={handleHomeNavigation} />
              <NavItem icon={QrCode} text="QR Code" onClick={handleQRCodeNavigation} />
              <NavItem icon={Wallet} text="Wallet" onClick={handlewalletNavigation} />
              <NavItem icon={Bell} text="Notifications" onClick={handlenotificationnavigation} />
            </div>

            {/* Menu Button for Web and Mobile */}
            {isMobile ? (
              <button onClick={() => setIsMobileMenuOpen(true)}>
                <NavItem icon={Menu} text="Menu" />
              </button>
            ) : (
              <button onClick={() => setIsSideSliderOpen(!isSideSliderOpen)}>
                <NavItem icon={Menu} text="Menu" />
              </button>
            )}

            <div className="relative" onClick={handleimageuserprofile}>
  <div className="absolute -inset-[4px] bg-gradient-to-r from-[#3DE9DC] via-[#889EE7] to-[#DC3DF7] rounded-full animate-spin
   shadow-[0_0_15px_rgba(61,233,220,0.9),0_0_25px_rgba(136,158,231,0.5),0_0_35px_rgba(220,61,247,0.5)]
  " aria-hidden="true"></div>
  <div className="absolute inset-0 bg-[#0D182E] rounded-full" aria-hidden="true"></div>
  <img 
    src={profileImage} 
    alt="Profile" 
    className="relative z-10 w-10 h-10 rounded-full object-cover" 
  />
</div>
          </div>
        </div>
      </nav>

      {/* Main Content Area */}
      <Outlet />
    </div>
  );
};

export default Dashboard;