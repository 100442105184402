import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Share2 } from 'lucide-react';
import logo from "./assets/images/loogfinal.png";
import companyLogo from "./assets/images/loogfinal.png";
import youtube from "./assets/images/ii1.png";
import dribble from "./assets/images/ii2.png"
import twitterIcon from "./assets/images/ii3.png";
import instagramIcon from "./assets/images/ii4.png";
import linkedinIcon from "./assets/images/ii5.png";
import emailbanner from "./assets/images/emailbanner.jpeg";


const EmailSignature = () => {
  const userData = {
    name: "John Doe",
    position: "Marketing Manager",
    qrCodeData: "https://reviewpay.com/profile/johndoe"
  };

  const handleShareQRCode = () => {
    navigator.clipboard.writeText(userData.qrCodeData)
      .then(() => alert('QR Code link copied to clipboard!'));
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      {/* Top Image Section - 30% Height */}
      <div className="h-72 w-full">
        <img 
          src={emailbanner}
          alt="Email Signature Header" 
          className="w-full h-full object-cover"
        />
      </div>

      {/* Main Content Section */}
      <div className="w-full bg-[#B7BDCA] p-8">
        {/* Heading */}
        <h1 className="text-3xl font-['GeneralSansBold'] text-black text-center mb-7">
        Thank you Rita for your review!
        </h1>

        {/* Horizontal Line */}
        <div className="w-full h-0.5 bg-black mb-10"></div>

        {/* QR Code Container - Added rounded borders and shadow */}
        <div className="flex justify-center mb-6">
          <div className="p-4 bg-[#0D182E] rounded-3xl shadow-lg inline-block">
            <QRCode
              value={userData.qrCodeData}
              size={200}
              logoImage={logo}
              logoWidth={50}
              logoHeight={50}
              logoPadding={5}
              logoPaddingStyle='square'
              logoOpacity={1}
              quietZone={10}
              fgColor="#FFFFFF"
              bgColor="#0D182E"
            />
          </div>
        </div>

        {/* Refer Now and Earn Text */}
        <h2 className=" text-3xl font-['GeneralSansMedium'] text-center text-[#0D182E] mb-10">
          Refer now and Earn
        </h2>

        {/* Share QR Code Button */}
        <div className="flex justify-center mb-2">
          <button 
            onClick={handleShareQRCode}
            className="flex items-center justify-center bg-[#0D182E] text-white px-6 py-3 rounded-2xl hover:bg-gray-800 transition-colors w-3/4 font-[GeneralSansMedium]"
          >
            <span className="text-center">Share QR Code</span>
          </button>
        </div>

        {/* Refer a Friend Text */}
        <p className="text-center font-['GeneralSansMedium'] text-[#0D182E] mb-10 mt-5">
          Refer a friend and earn 9% cash back
        </p>

        <div className="w-full h-0.5 bg-black mb-10"></div>

        {/* Footer */}
        <div className="text-center">
          {/* Company Logo */}
          <div className="flex justify-center mb-4">
            <img 
              src={companyLogo} 
              alt="Company Logo" 
              className="h-20 w-auto"
            />
          </div>

          {/* Company Name */}
          <h2 className="text-2xl font-['VerminViva'] text-[#0D182E] mb-2">
            Review Pay
          </h2>

          <p className="font-['GeneralSansRegular'] text-black text-center mb-4">
          You are receiving this email because you opted in via our website.
                    </p>

          {/* Social Media Icons */}
          <div className="flex justify-center space-x-4 mb-4">
            <a href="#" className="w-5 h-5">
              <img src={youtube} alt="Facebook" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={dribble} alt="Dribble" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={twitterIcon} alt="Twitter" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={instagramIcon} alt="Instagram" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={linkedinIcon} alt="LinkedIn" className="w-full h-full" />
            </a>
          </div>

          {/* Company Address */}
          <p className="text-sm font-['GeneralSansRegular'] text-black">
            123 Review Street, Tech City, Innovation District 12345
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailSignature;