import React, { useState, useRef } from 'react';
import authImage from "./assets/images/authimage.png";

const SecurityCodeVerification = () => {
  const [code, setCode] = useState(['', '', '', '']);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const handleInputChange = (index, value) => {
    // Ensure only single digit numbers (1-9)
    if (/^[1-9]?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Move focus to next input if a digit is entered
      if (value && index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    // Handle backspace to move focus and clear previous input
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const verifyCode = () => {
    // Logic to verify the entered code
    const enteredCode = code.join('');
    console.log('Entered Code:', enteredCode);
    // Add your verification logic here
  };

  return (
    <div className="min-h-screen py-10 flex flex-col items-center p-4 pt-32">
      <div className="w-full max-w-md text-center">
        <h1 className="text-4xl font-[GeneralSansSemibold] text-white mb-8">
          Verify
        </h1>
        <div className="mb-8">
          <img 
            src={authImage} 
            alt="Authentication Guide" 
            className="w-52 h-52 mx-auto"
          />
        </div>
        <p className="text-white text-sm mb-6 font-[GeneralSansRegular]">
        Enter the code that you received through the text message
         </p>

        {/* Code Input Boxes */}
        <div className="flex justify-center space-x-4 mb-6">
          {code.map((digit, index) => (
            <input
              key={index}
              ref={inputRefs[index]}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onFocus={() => setFocusedIndex(index)}
              onBlur={() => setFocusedIndex(null)}
              className="w-20 h-12 bg-transparent border-2 border-[#182B53] text-white text-center text-2xl 
                         focus:outline-none rounded-md"
              placeholder={focusedIndex === index ? "" : "0"}
            />
          ))}
        </div>

        <button 
          onClick={verifyCode}
          className="w-full bg-[#0D182E] text-white px-6 py-3 rounded-xl transition-colors 
                     flex items-center justify-center font-[GeneralSansMedium] 
                     hover:bg-[#1A3A5E] active:bg-[#0A1625]"
        >
          Submit Code
        </button>
      </div>
    </div>
  );
};

export default SecurityCodeVerification;