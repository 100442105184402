import React, { useState } from 'react';
import { 
  ArrowLeft, 
  ArrowRight, 
  Upload, 
  UserPlus,
  Users,
  Building2,
  Receipt,
  Mail,
  User
} from 'lucide-react';
import logo from "./assets/images/loogfinal.png"
import { useNavigate } from 'react-router-dom';

const EmployeeInvitation = () => {
  const [employees, setEmployees] = useState([{
    id: 1,
    profilePic: null,
    name: '',
    identificationNumber: '',
    designation: '',
    email: ''
  }]);
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);

  const addEmployee = () => {
    setEmployees([...employees, {
      id: employees.length + 1,
      profilePic: null,
      name: '',
      identificationNumber: '',
      designation: '',
      email: ''
    }]);
  };

  const handleImageUpload = (index, files) => {
    const fileArray = Array.from(files);
    const reader = new FileReader();
    reader.onload = (e) => {
      const updatedEmployees = [...employees];
      updatedEmployees[index].profilePic = e.target.result;
      setEmployees(updatedEmployees);
    };
    if (fileArray[0]) {
      reader.readAsDataURL(fileArray[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    setIsDragging(false);
    handleImageUpload(index, e.dataTransfer.files);
  };

  const removeImage = (index) => {
    const updatedEmployees = [...employees];
    updatedEmployees[index].profilePic = null;
    setEmployees(updatedEmployees);
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-20">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">
          Invite Employees
        </h2>

        <form className="space-y-6 -mb-6">
          {employees.map((employee, index) => (
            <div key={employee.id} className="space-y-6">
              {/* File Upload */}
              <div 
                className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
                  ${isDragging 
                    ? 'border-white/50 bg-white/10 scale-[1.02]' 
                    : 'border-white/30 hover:border-white/50 bg-white/5'}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, index)}
                onClick={() => document.getElementById(`fileInput-${employee.id}`).click()}
              >
                <input
                  id={`fileInput-${employee.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(index, e.target.files)}
                />
                
                {employee.profilePic ? (
                  <div className="relative">
                    <img 
                      src={employee.profilePic} 
                      alt="Profile" 
                      className="w-32 h-32 rounded-full object-cover mx-auto mb-4"
                    />
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 
                                 flex items-center justify-center text-xs hover:bg-red-600"
                    >
                      ×
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                      <Upload className="h-8 w-8 text-blue-950" />
                    </div>
                    <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
                      Click to upload or drag and drop
                    </p>
                    <p className="text-xs text-gray-500">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </>
                )}
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="relative">
                  <label 
                    htmlFor={`name-${employee.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Full Name
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <User className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`name-${employee.id}`}
                    type="text"
                    value={employee.name}
                    onChange={(e) => {
                      const updatedEmployees = [...employees];
                      updatedEmployees[index].name = e.target.value;
                      setEmployees(updatedEmployees);
                    }}
                    placeholder="Enter full name"
                    required
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                               text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                               focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                               transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>

                <div className="relative">
                  <label 
                    htmlFor={`id-${employee.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Identification Number
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <Receipt className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`id-${employee.id}`}
                    type="text"
                    value={employee.identificationNumber}
                    onChange={(e) => {
                      const updatedEmployees = [...employees];
                      updatedEmployees[index].identificationNumber = e.target.value;
                      setEmployees(updatedEmployees);
                    }}
                    placeholder="Enter identification number"
                    required
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                               text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                               focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                               transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>

                <div className="relative">
                  <label 
                    htmlFor={`designation-${employee.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Designation
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <Building2 className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`designation-${employee.id}`}
                    type="text"
                    value={employee.designation}
                    onChange={(e) => {
                      const updatedEmployees = [...employees];
                      updatedEmployees[index].designation = e.target.value;
                      setEmployees(updatedEmployees);
                    }}
                    placeholder="Enter designation"
                    required
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                               text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                               focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                               transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>

                <div className="relative">
                  <label 
                    htmlFor={`email-${employee.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Email Address
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <Mail className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`email-${employee.id}`}
                    type="email"
                    value={employee.email}
                    onChange={(e) => {
                      const updatedEmployees = [...employees];
                      updatedEmployees[index].email = e.target.value;
                      setEmployees(updatedEmployees);
                    }}
                    placeholder="Enter email address"
                    required
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                               text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                               focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                               transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>
              </div>
            </div>
          ))}

          {/* Add Another Employee Button */}
          <button
            type="button"
            onClick={addEmployee}
            className="w-full flex items-center justify-center px-6 py-3 border-2 border-white/30 
                       text-white rounded-lg font-['GeneralSansMedium'] 
                       hover:bg-white/10 transition-all duration-200 mt-6"
          >
            <UserPlus className="w-5 h-5 mr-2" />
            Add Another Employee
          </button>

          {/* Navigation Buttons */}
          <div className="flex justify-between items-center mt-12 pt-6 border-t border-white/20">
            <button
              type="button"
              onClick={() => navigate('/BusinessDashboard')}
              className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
                         font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-200"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </button>
            <button
              type="submit"
              onClick={() => navigate('/')}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
                         text-white rounded-lg font-['GeneralSansMedium'] hover:opacity-90 
                         transition-all duration-200 shadow-lg hover:shadow-xl 
                         hover:scale-[1.02]"
            >
              Next Step
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          </div>
        </form>

        <div className="mt-9 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Already have an account?
          </span>
          <button 
            onClick={() => navigate('/SignIn')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeInvitation;