import React from 'react';
import logo from "./assets/images/bannerlogoleft2.png";
import companyLogo from "./assets/images/loogfinal.png";
import youtube from "./assets/images/ii1.png";
import dribble from "./assets/images/ii2.png"
import twitterIcon from "./assets/images/ii3.png";
import instagramIcon from "./assets/images/ii4.png";
import linkedinIcon from "./assets/images/ii5.png";
import emailbanner from "./assets/images/rafiki.png";

const EmailSignatureInvite2 = () => {
  return (
    <div className="w-full max-w-4xl mx-auto relative">


      {/* Main Content Section */}
      <div className="w-full bg-[#B7BDCA] p-8">
        {/* Top Image Section - Centered */}
              {/* Banner Logo Overlay */}
      <div className="absolute top-10 left-4  ">
        <img 
          src={logo} 
          alt="Company Logo" 
          className="w-14 h-14 object-contain"
        />
      </div>
        <div className="h-72 w-full flex justify-center items-center mb-6">
          <img 
            src={emailbanner}
            alt="Email Signature Header" 
            className="max-h-full max-w-full object-contain"
          />
        </div>

        <h1 className="text-3xl font-['GeneralSansBold'] text-[#0D182E] text-center mb-7">
        Let's Join Review Pay
        </h1>

        {/* Horizontal Line */}
        <div className="w-full h-0.5 bg-black mb-10"></div>

        {/* Paragraph Text */}
        <p className="text-xl font-['GeneralSansMedium'] text-center text-[#0D182E] mb-5">
        Join me at Review Pay and get up to 
        </p>
        <h1 className=" text-4xl font-['GeneralSansSemibold'] text-black text-center mb-2">
        $150
        </h1>
        <p className="text-xl font-['GeneralSansMedium'] text-center text-[#0D182E] mb-10">
        for referal
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
            <button 
              type="button" 
              className="flex items-center justify-center w-48 text-white bg-black/90 h-14 rounded-xl mx-auto sm:mx-0 hover:bg-black/80 transition-all duration-300 backdrop-blur-sm"
            >
              <div className="mr-3">
                <svg viewBox="0 0 384 512" width="30">
                  <path 
                    fill="currentColor" 
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs font-['GeneralSansRegular']">Download on the</div>
                <div className="-mt-1 font-['GeneralSansBold'] text-xl">App Store</div>
              </div>
            </button>

            <button 
              type="button" 
              className="flex items-center justify-center w-48 text-white bg-black/90 h-14 rounded-xl mx-auto sm:mx-0 hover:bg-black/80 transition-all duration-300 backdrop-blur-sm"
            >
              <div className="mr-3">
                <svg viewBox="30 336.7 120.9 129.2" width="30">
                  <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                  <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                  <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                  <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                </svg>
              </div>
              <div>
                <div className="text-xs font-['GeneralSansRegular']">GET IT ON</div>
                <div className="-mt-1 font-['GeneralSansBold'] text-xl">Google Play</div>
              </div>
            </button>
          </div>

        <div className="w-full h-0.5 bg-black mb-10 mt-10"></div>

        {/* Footer */}
        <div className="text-center">
          {/* Company Logo */}
          <div className="flex justify-center mb-4">
            <img 
              src={companyLogo} 
              alt="Company Logo" 
              className="h-20 w-auto"
            />
          </div>

          {/* Company Name */}
          <h2 className="text-2xl font-['VerminViva'] text-[#0D182E] mb-2">
            Review Pay
          </h2>

          <p className="font-['GeneralSansRegular'] text-black text-center mb-4">
            You are receiving this email because you opted in via our website.
          </p>

          {/* Social Media Icons */}
          <div className="flex justify-center space-x-4 mb-4">
            <a href="#" className="w-5 h-5">
              <img src={youtube} alt="Facebook" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={dribble} alt="Dribble" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={twitterIcon} alt="Twitter" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={instagramIcon} alt="Instagram" className="w-full h-full" />
            </a>
            <a href="#" className="w-5 h-5">
              <img src={linkedinIcon} alt="LinkedIn" className="w-full h-full" />
            </a>
          </div>

          {/* Company Address */}
          <p className="text-sm font-['GeneralSansRegular'] text-black">
            123 Review Street, Tech City, Innovation District 12345
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmailSignatureInvite2;