import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          
          <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
            Review pay
          </h1>

          <h1 className="text-white text-6xl font-['GeneralSansBold'] mb-4">
            404
          </h1>

          <h2 className="text-white text-3xl font-['GeneralSansBold'] mb-4">
            Page Not Found
          </h2>

          <p className="text-gray-200 font-['GeneralSansMedium'] text-lg mb-6">
            Oops! The page you are looking for seems to have wandered off. 
            Don't worry, our team is on a mission to guide you back to safety.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
          <button 
            onClick={handleGoHome}
            className="flex items-center justify-center w-48 text-white bg-black/90 h-14 rounded-xl 
                       mx-auto sm:mx-0 hover:bg-black/80 transition-all duration-300 
                       backdrop-blur-sm font-['GeneralSansBold']"
          >
            Return Home
          </button>
        </div>
      </div>

      <p className="text-gray-200 font-['GeneralSansRegular'] text-sm mt-8">
        © {new Date().getFullYear()} Review Pay. All rights reserved.
      </p>
    </div>
  );
};

export default NotFound;