import React, { useState } from 'react';
import { Upload, ArrowLeft, ArrowRight, Camera, Image as ImageIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const BusinessMediaUploadStep = () => {
  const [formData, setFormData] = useState({
    profileMedia: null,
    businessLogo: null
  });
  const navigate = useNavigate();
  
  const [isDraggingProfile, setIsDraggingProfile] = useState(false);
  const [isDraggingLogo, setIsDraggingLogo] = useState(false);

  const handleProfileMediaDragOver = (e) => {
    e.preventDefault();
    setIsDraggingProfile(true);
  };

  const handleProfileMediaDragLeave = () => {
    setIsDraggingProfile(false);
  };

  const handleProfileMediaDrop = (e) => {
    e.preventDefault();
    setIsDraggingProfile(false);
    handleProfileMediaUpload(e.dataTransfer.files);
  };

  const handleLogoMediaDragOver = (e) => {
    e.preventDefault();
    setIsDraggingLogo(true);
  };

  const handleLogoMediaDragLeave = () => {
    setIsDraggingLogo(false);
  };

  const handleLogoMediaDrop = (e) => {
    e.preventDefault();
    setIsDraggingLogo(false);
    handleLogoUpload(e.dataTransfer.files);
  };

  const handleProfileMediaUpload = (files) => {
    const file = files[0];
    if (file && (file.type.startsWith('image/') || file.type.startsWith('video/'))) {
      setFormData(prevState => ({
        ...prevState,
        profileMedia: file
      }));
    }
  };

  const handleLogoUpload = (files) => {
    const file = files[0];
    if (file && (file.type.startsWith('image/') || file.type === 'image/gif')) {
      setFormData(prevState => ({
        ...prevState,
        businessLogo: file
      }));
    }
  };

  const removeProfileMedia = () => {
    setFormData(prevState => ({
      ...prevState,
      profileMedia: null
    }));
  };

  const removeBusinessLogo = () => {
    setFormData(prevState => ({
      ...prevState,
      businessLogo: null
    }));
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      {/* Media Upload Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Business Media Upload</h2>
        
        <form className="space-y-6 -mb-6">
          {/* Profile Media Upload */}
          <div 
            className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
              ${isDraggingProfile 
                ? 'border-white/50 bg-white/10 scale-[1.02]' 
                : 'border-white/30 hover:border-white/50 bg-white/5'}`}
            onDragOver={handleProfileMediaDragOver}
            onDragLeave={handleProfileMediaDragLeave}
            onDrop={handleProfileMediaDrop}
            onClick={() => document.getElementById('profileMediaInput').click()}
          >
            <input
              id="profileMediaInput"
              type="file"
              className="hidden"
              accept="image/*,video/*"
              onChange={(e) => handleProfileMediaUpload(e.target.files)}
            />
            <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
              <Camera className="h-8 w-8 text-blue-950" />
            </div>
            <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
              Upload Profile Picture or Video
            </p>
            <p className="text-xs text-gray-500">
              Click to upload or drag and drop (Image or Video)
            </p>
          </div>

          {/* Profile Media Preview */}
          {formData.profileMedia && (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
              <div className="relative">
                {formData.profileMedia.type.startsWith('image/') ? (
                  <img 
                    src={URL.createObjectURL(formData.profileMedia)} 
                    alt="Profile Media" 
                    className="w-full h-24 object-cover rounded-lg"
                  />
                ) : (
                  <video 
                    src={URL.createObjectURL(formData.profileMedia)} 
                    className="w-full h-24 object-cover rounded-lg"
                    controls
                  />
                )}
                <button
                  type="button"
                  onClick={removeProfileMedia}
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                             flex items-center justify-center text-xs hover:bg-red-600"
                >
                  ×
                </button>
              </div>
            </div>
          )}

          {/* Business Logo Upload */}
          <div 
            className={`mt-8 border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
              ${isDraggingLogo 
                ? 'border-white/50 bg-white/10 scale-[1.02]' 
                : 'border-white/30 hover:border-white/50 bg-white/5'}`}
            onDragOver={handleLogoMediaDragOver}
            onDragLeave={handleLogoMediaDragLeave}
            onDrop={handleLogoMediaDrop}
            onClick={() => document.getElementById('logoMediaInput').click()}
          >
            <input
              id="logoMediaInput"
              type="file"
              className="hidden"
              accept="image/*,image/gif"
              onChange={(e) => handleLogoUpload(e.target.files)}
            />
            <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
              <ImageIcon className="h-8 w-8 text-blue-950" />
            </div>
            <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
              Upload Business Logo
            </p>
            <p className="text-xs text-gray-500">
              Click to upload or drag and drop (Image or GIF)
            </p>
          </div>

          {/* Business Logo Preview */}
          {formData.businessLogo && (
            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
              <div className="relative">
                <img 
                  src={URL.createObjectURL(formData.businessLogo)} 
                  alt="Business Logo" 
                  className="w-full h-24 object-cover rounded-lg"
                />
                <button
                  type="button"
                  onClick={removeBusinessLogo}
                  className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 
                             flex items-center justify-center text-xs hover:bg-red-600"
                >
                  ×
                </button>
              </div>
            </div>
          )}

          {/* Navigation Buttons */}
          <div className="flex justify-between items-center mt-12 pt-6 border-t border-white/20">
            <button
              type="button"
              onClick={() => navigate('/BusinessDetails')}
              className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
                         font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-200"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Back
            </button>
            <button
              type="submit"
              onClick={() => navigate('/BusinessVerification')}
              className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
                         text-white rounded-lg font-['GeneralSansMedium'] hover:opacity-90 
                         transition-all duration-200 shadow-lg hover:shadow-xl 
                         hover:scale-[1.02]"
            >
              Next Step
              <ArrowRight className="w-4 h-4 ml-2" />
            </button>
          </div>
        </form>
        <div className="mt-9 text-center">
          <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
            Already have an account?
          </span>
          <button 
            onClick={() => navigate('/SignIn')}
            className="text-white font-['GeneralSansMedium'] hover:underline"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessMediaUploadStep;