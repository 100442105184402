import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './assets/images/loogfinal.png';

const ComingSoonPage = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const formDataObj = new FormData();
      formDataObj.append('email', formData.email);
      formDataObj.append('password', formData.password);

      const response = await fetch('http://54.79.82.181:8000/reviewpayrole_api/login/', {
        method: 'POST',
        body: formDataObj
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('refreshToken', data.refresh_token);
        navigate('/BusinessDashboard');
      } else {
        setError('Invalid email or password');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-44 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>

          <h1 className="text-white text-3xl font-['GeneralSansBold'] mb-4">
            COMING SOON 2025
          </h1>
          <p className="text-gray-200 font-['GeneralSansMedium'] text-lg mb-6">
            We're working hard to bring the Review Pay platform to life. 
            Stay tuned for an innovative solution that will transform how businesses 
            manage payroll and reviews.
          </p>

          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-6">
            <button 
              type="button" 
              className="flex items-center justify-center w-48 text-white bg-black/90 h-14 rounded-xl mx-auto sm:mx-0 hover:bg-black/80 transition-all duration-300 backdrop-blur-sm"
            >
              <div className="mr-3">
                <svg viewBox="0 0 384 512" width="30">
                  <path 
                    fill="currentColor" 
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs font-['GeneralSansRegular']">Download on the</div>
                <div className="-mt-1 font-['GeneralSansBold'] text-xl">App Store</div>
              </div>
            </button>

            <button 
              type="button" 
              className="flex items-center justify-center w-48 text-white bg-black/90 h-14 rounded-xl mx-auto sm:mx-0 hover:bg-black/80 transition-all duration-300 backdrop-blur-sm"
            >
              <div className="mr-3">
                <svg viewBox="30 336.7 120.9 129.2" width="30">
                  <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
                  <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
                  <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
                  <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
                </svg>
              </div>
              <div>
                <div className="text-xs font-['GeneralSansRegular']">GET IT ON</div>
                <div className="-mt-1 font-['GeneralSansBold'] text-xl">Google Play</div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Login Box with integrated functionality */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-md border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Login</h2>
        
        {error && (
          <div className=" text-pink-500 px-4 py-2 rounded-lg text-lg mb-4 font-[GeneralSansRegular]">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              required
              className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                       text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                       focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                       transition-all duration-300 placeholder:font-['GeneralSansRegular']"
            />
          </div>
          <div>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Password"
              required
              className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg 
                       text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                       focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                       transition-all duration-300 placeholder:font-['GeneralSansRegular']"
            />
          </div>
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-gradient-to-r from-pink-500 to-purple-600 text-white 
                     py-3 px-4 rounded-lg font-['GeneralSansBold'] hover:opacity-90 
                     transition-all duration-300 shadow-lg hover:shadow-xl 
                     hover:scale-[1.02] disabled:opacity-70 disabled:hover:scale-100"
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </button>
        </form>
        <div className="mt-8 text-center">
        <span className="text-gray-200 font-['GeneralSansRegular'] text-sm mr-2">
          Don't have an account?
        </span>
        <button 
          onClick={() => navigate('/ChooseRole')}
          className="text-white font-['GeneralSansMedium'] hover:underline"
        >
          Join now
        </button>
      </div>
      </div>

      <p className="text-gray-200 font-['GeneralSansRegular'] text-sm mt-8">
        © {new Date().getFullYear()} Review Pay. All rights reserved.
      </p>
    </div>
  );
};

export default ComingSoonPage;