import React, { useState } from 'react';
import { Calendar, Filter, FileText } from 'lucide-react';

const InvoiceHistoryComponent = () => {
  const [typeFilter, setTypeFilter] = useState('');
  const [dateFilter, setDateFilter] = useState('');

  const invoiceData = [
    { 
      id: 'INV-001',
      type: 'Referral',
      date: '2024-01-15',
      amount: '$250.00',
      status: 'Paid',
      backgroundColor: 'bg-[#527DE6]'
    },
    { 
      id: 'INV-002',
      type: 'Review',
      date: '2024-02-20',
      amount: '$175.50',
      status: 'Pending',
      backgroundColor: 'bg-[#65D4B0]'
    },
    { 
      id: 'INV-003',
      type: 'Referral',
      date: '2024-03-10',
      amount: '$300.75',
      status: 'Paid',
      backgroundColor: 'bg-[#DB005F]'
    },
    { 
      id: 'INV-004',
      type: 'Review',
      date: '2024-04-05',
      amount: '$225.25',
      status: 'Overdue',
      backgroundColor: 'bg-[#FE8705]'
    },
    { 
      id: 'INV-005',
      type: 'Referral',
      date: '2024-05-22',
      amount: '$190.00',
      status: 'Pending',
      backgroundColor: 'bg-[#DC3DF7]'
    }
  ];

  // Filter invoices based on type and date
  const filteredInvoices = invoiceData.filter(invoice => 
    (typeFilter === '' || invoice.type === typeFilter) &&
    (dateFilter === '' || invoice.date.startsWith(dateFilter))
  );

  return (
    <div className="my-20 mx-10">
      {/* Header */}
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            INVOICE HISTORY
          </h1>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-[#0D182E] rounded-lg p-6 mb-6 flex space-x-4">
        {/* Type Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Type
          </label>
          <div className="relative">
            <select 
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg appearance-none"
            >
              <option value="">All Types</option>
              <option value="Referral">Referral</option>
              <option value="Review">Review</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Filter className="text-white" size={20} />
            </div>
          </div>
        </div>

        {/* Date Filter */}
        <div className="flex-1">
          <label className="text-white font-['GeneralSansMedium'] mb-2 block">
            Filter by Date
          </label>
          <div className="relative">
            <input 
              type="month"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
              className="w-full bg-[#1A2E56] text-white p-3 rounded-lg"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
              <Calendar className="text-white" size={20} />
            </div>
          </div>
        </div>
      </div>

      {/* Invoice List */}
      <div className="bg-[#0D182E] rounded-lg p-10 mt-5">
        {filteredInvoices.map((invoice, index) => (
          <div key={index} className="flex items-center mb-4">
            {/* Diamond Container */}
            <div className={`w-[45px] h-[45px] transform rotate-45 border border-[#1A2E56] rounded-[10px] overflow-hidden ${invoice.backgroundColor} mr-2.5`}>
              <div className="w-full h-full -rotate-45 flex items-center justify-center">
                <FileText className="text-white" size={20} />
              </div>
            </div>

            {/* Invoice Details */}
            <div className="ml-4 flex-1">
              <p className="text-base text-white font-['GeneralSansMedium']">{invoice.id}</p>
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.type} Invoice</p>
              <p className="text-sm text-[#64718C] font-['GeneralSansRegular']">{invoice.date}</p>
            </div>

            {/* Invoice Amount and Status */}
            <div className="flex flex-col items-end">
              <p className="text-base text-white font-['GeneralSansMedium']">{invoice.amount}</p>
              <p className={`text-sm font-['GeneralSansRegular'] ${
                invoice.status === 'Paid' ? 'text-[#65D4B0]' : 
                invoice.status === 'Pending' ? 'text-[#FE8705]' : 
                'text-[#DB005F]'
              }`}>
                {invoice.status}
              </p>
            </div>
          </div>
        ))}

        {/* No Results Message */}
        {filteredInvoices.length === 0 && (
          <div className="text-center text-[#64718C] font-['GeneralSansRegular']">
            No invoices found matching the selected filters.
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoiceHistoryComponent;