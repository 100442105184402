import React, { useState } from 'react';
import authImage from "./assets/images/authimage.png";
import { useNavigate } from 'react-router-dom';

const TextMessageAuth = () => {
  const [copiedCode, setCopiedCode] = useState(false);
  const authCode = "6HKHMDC6CKGYKFBB";
  const navigate =useNavigate();
 
  const handleSendcode = () => {
    navigate("EnterSecurityCode");

  }

  return (
    <div className="min-h-screen py-10 flex flex-col items-center p-4 pt-32">
      <div className="w-full max-w-md text-center">
        <h1 className="text-4xl font-[GeneralSansSemibold] text-white mb-8">
          Text Message
        </h1>
        <div className="mb-8">
          <img 
            src={authImage} 
            alt="Authentication Guide" 
            className="w-52 h-52 mx-auto"
          />
        </div>
        <p className="text-white text-sm mb-6 font-[GeneralSansRegular]">
          If you can't scan the QR code with your camera, enter the following code into the authentication app to link it to your X account.
        </p>

        {/* Country Code and Phone Number Inputs */}
        <div className="flex space-x-4 mb-6">
          <input 
            type="text" 
            placeholder="+1" 
            className="w-1/4 bg-transparent border-2 border-[#182B53] text-white text-center py-2 focus:outline-none rounded-md placeholder:text-white"
          />
          <input 
            type="text" 
            placeholder="Phone Number" 
            className="w-3/4 bg-transparent border-2 border-[#182B53] text-white py-2 p-2 focus:outline-none rounded-md placeholder:text-white"
          />
        </div>

        <button 
          className="w-full bg-[#0D182E] text-white px-6 py-3 rounded-xl transition-colors flex items-center justify-center font-[GeneralSansMedium]"
        onClick={handleSendcode}
        >
          Send Code
        </button>
      </div>
    </div>
  );
};

export default TextMessageAuth;