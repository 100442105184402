import React, { useState } from 'react';
import video1 from "./assets/images/reviewvideo.mp4";
import logo1 from "./assets/images/avatar2.png";
import logo2 from "./assets/images/logofinal2.png";
import { Upload, Plus } from 'lucide-react';

const ReviewSection = () => {
  // Consolidated state management
  const [formData, setFormData] = useState({
    overallRating: 0,
    selectedOptions: {},
    recommendRating: 0,
    navigationRating: 0,
    customerServiceRating: 0,
    qualityRating: 0,
    comment: '',
    dynamicFields: {}
  });

  const [showModal, setShowModal] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [newFieldQuestion, setNewFieldQuestion] = useState('');
  const [newFieldType, setNewFieldType] = useState(null);
  
  // New state for MCQ options
  const [mcqOptions, setMcqOptions] = useState(['']);
  const [isEnteringMcqOptions, setIsEnteringMcqOptions] = useState(false);
  const questions = [
    {
      id: 1,
      question: 'What other features should we have in our app?',
      type: 'mcq',
      options: ['Built-in Review Form Generator', 'Advanced Analytics', 'Collaborative Tools', 'Customization Options']
    },
    {
      id: 2,
      question: 'Choose the best option you like',
      type: 'mcq',
      options: ['User-Friendly Interface', 'Robust Reporting', 'Integration Capabilities', 'Cost-Effectiveness']
    }
  ];

  // Existing handlers remain the same...
  const updateFormData = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDynamicFieldInput = (fieldId, value) => {
    setFormData(prev => ({
      ...prev,
      dynamicFields: {
        ...prev.dynamicFields,
        [fieldId]: value
      }
    }));
  };

  const handleMCQSelection = (questionId, optionIndex) => {
    setFormData(prev => ({
      ...prev,
      selectedOptions: {
        ...prev.selectedOptions,
        [questionId]: optionIndex
      }
    }));
  };

  // Modified addField function to handle question and MCQ options
  const startAddField = (type) => {
    setNewFieldType(type);
    setNewFieldQuestion('');
    
    // If MCQ, prepare for option input
    if (type === 'mcq') {
      setIsEnteringMcqOptions(false);
      setMcqOptions(['']);
    }
    
    setShowModal(true);
  };

  // Add option for MCQ
  const addMcqOption = () => {
    setMcqOptions([...mcqOptions, '']);
  };

  // Update specific MCQ option
  const updateMcqOption = (index, value) => {
    const newOptions = [...mcqOptions];
    newOptions[index] = value;
    setMcqOptions(newOptions);
  };

  const addField = () => {
    if (!newFieldQuestion.trim()) {
      alert('Please enter a question for the field');
      return;
    }

    // Special handling for MCQ to ensure options are added
    if (newFieldType === 'mcq') {
      // Check if all options have a value
      const validOptions = mcqOptions.filter(opt => opt.trim() !== '');
      if (validOptions.length < 2) {
        alert('Please add at least 2 options for Multiple Choice Question');
        return;
      }

      const newField = { 
        type: newFieldType, 
        id: Date.now(), 
        question: newFieldQuestion,
        options: validOptions
      };

      setFormFields(prevFields => [...prevFields, newField]);
      setShowModal(false);
      setNewFieldType(null);
      setNewFieldQuestion('');
      setMcqOptions(['']);
    } else {
      const newField = { 
        type: newFieldType, 
        id: Date.now(), 
        question: newFieldQuestion
      };

      // Additional configuration for specific field types
      if (newFieldType === 'mcq') {
        newField.options = ['Option A', 'Option B', 'Option C', 'Option D'];
      }

      setFormFields(prevFields => [...prevFields, newField]);
      setShowModal(false);
      setNewFieldType(null);
      setNewFieldQuestion('');
    }
  };
  // Render dynamic field based on type
  const renderDynamicField = (field) => {
    switch(field.type) {
      case 'mcq':
        return (
          <div key={field.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
            <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{field.question}</h3>
            <div className="space-y-4">
              {field.options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleDynamicFieldInput(field.id, index)}
                  className={`w-full p-4 rounded-lg border-2 flex justify-between items-center bg-[#B7BDCA] ${
                    formData.dynamicFields[field.id] === index 
                      ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                      : 'border-[#B7BDCA] hover:border-[#DC3DF7]'
                  }`}
                >
                  <span className="text-[#0D182E]">{option}</span>
                  <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                    formData.dynamicFields[field.id] === index 
                      ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                      : 'border-[#0D182E]'
                  }`}>
                    {formData.dynamicFields[field.id] === index && (
                      <div className="w-full p-2.5 bg-[#DC3DF7] rounded-full border-white border-2" />
                    )}
                  </div>
                </button>
              ))}
            </div>
          </div>
        );
      case 'text':
        return (
          <div key={field.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
            <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{field.question}</h3>
            <textarea
              value={formData.dynamicFields[field.id] || ''}
              onChange={(e) => handleDynamicFieldInput(field.id, e.target.value)}
              placeholder="Enter Here"
              className="w-full h-32 p-4 bg-[#1A2747] text-white rounded-lg border border-gray-700 focus:outline-none focus:border-blue-500 resize-none"
            />
          </div>
        );
      case 'slider':
        return (
          <div key={field.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
            <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{field.question}</h3>
            <div className="flex items-center space-x-4">
              <input 
                type="range" 
                min="0" 
                max="10" 
                value={formData.dynamicFields[field.id] || 5}
                onChange={(e) => handleDynamicFieldInput(field.id, e.target.value)}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <span className="text-white text-lg">
                {formData.dynamicFields[field.id] || 5}/10
              </span>
            </div>
          </div>
        );
        case 'satisfaction':
        return (
          <div key={field.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
            <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{field.question}</h3>
            <div className="flex space-x-6 justify-center">
              {[1, 2, 3, 4, 5].map((rating) => (
                <button
                  key={rating}
                  onClick={() => handleDynamicFieldInput(field.id, rating)}
                  className="w-14 h-14 relative transition-all"
                >
                  <img
                    src={logo2} // Make sure to import logo2 or replace with appropriate image
                    alt={`Rating ${rating}`}
                    className="w-full h-full object-contain transition-opacity"
                    style={{ opacity: (formData.dynamicFields[field.id] || 0) >= rating ? 1 : 0.3 }}
                  />
                </button>
              ))}
            </div>
          </div>
        );

      case 'recommendation':
        return (
          <div key={field.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
            <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{field.question}</h3>
            <div className="flex justify-between mb-3">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
                <button
                  key={rating}
                  onClick={() => handleDynamicFieldInput(field.id, rating)}
                  className={`w-8 h-8 rounded flex items-center justify-center ${
                    (formData.dynamicFields[field.id] || 0) >= rating 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-700 text-white'
                  }`}
                >
                  {rating}
                </button>
              ))}
            </div>
          </div>
        );    
      default:
        return null;
    }
  };

  // Function to save the template
  const saveTemplate = () => {
    console.log('Form Data:', formData);
    alert('Template saved! Check console for details.');
  };

  // Image upload handler
  const handleImageUpload = () => {
    alert('Image upload functionality to be implemented');
  };


  return (
    <div className="w-full p-8 space-y-8" style={{ fontFamily: 'GeneralSansRegular' }}>
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8 mt-16">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">CREATE FEEDBACK</h1>
        </div>
      </div>

      {/* Video Section */}
      <div className="border-4 border-white rounded-xl overflow-hidden">
        <div className="relative w-full h-96">
          <video className="w-full h-full object-cover" autoPlay muted loop>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute bottom-8 left-8 flex items-center space-x-4">
            <img src={logo1} alt="Company Logo" className="h-16 w-auto" />
            <div className="text-white w-44">
              <h2 className="text-2xl font-bold">Crown Casino Melbourne</h2>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="bg-[#0D182E] p-8 rounded-2xl w-96 border border-[#1A2747] shadow-2xl">
            {!newFieldType ? (
              // Field type selection remains the same
              <div>
                <h2 className="text-2xl text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
                  Select Field Type
                </h2>
                <div className="space-y-4">
                  {[
                    { type: 'mcq', label: 'Multiple Choice Question', color: 'bg-[#527DE6] hover:bg-[#6A90F5]' },
                    { type: 'satisfaction', label: 'Satisfaction Rating',  color: 'bg-[#527DE6] hover:bg-[#6A90F5]' },
                    { type: 'recommendation', label: 'Recommendation Rating',  color: 'bg-[#527DE6] hover:bg-[#6A90F5]' },
                    { type: 'text', label: 'Text Input',  color: 'bg-[#527DE6] hover:bg-[#6A90F5]' },
                    { type: 'slider', label: 'Slider Rating',  color: 'bg-[#527DE6] hover:bg-[#6A90F5]' }
                  ].map((field) => (
                    <button 
                      key={field.type}
                      onClick={() => startAddField(field.type)} 
                      className={`w-full text-white py-3 rounded-lg transition-colors duration-300 ${field.color}`}
                    >
                      {field.label}
                    </button>
                  ))}
                  <button 
                    onClick={() => setShowModal(false)} 
                    className="w-full bg-[#1A2747] text-white py-3 rounded-lg hover:bg-[#2A3E5C] transition-colors duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : !isEnteringMcqOptions && newFieldType === 'mcq' ? (
              // Question input for MCQ
              <div>
                <h2 className="text-2xl text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
                  Enter MCQ Question
                </h2>
                <input
                  type="text"
                  value={newFieldQuestion}
                  onChange={(e) => setNewFieldQuestion(e.target.value)}
                  placeholder="Enter your multiple choice question"
                  className="w-full p-3 bg-[#1A2747] text-white rounded-lg border border-[#2A3E5C] mb-4 focus:outline-none focus:border-[#DC3DF7]"
                />
                <div className="flex space-x-4">
                  <button 
                    onClick={() => {
                      if (!newFieldQuestion.trim()) {
                        alert('Please enter a question');
                        return;
                      }
                      setIsEnteringMcqOptions(true);
                    }} 
                    className="flex-1 bg-[#DC3DF7] text-white py-3 rounded-lg hover:bg-[#E65BFA] transition-colors duration-300"
                  >
                    Next: Add Options
                  </button>
                  <button 
                    onClick={() => {
                      setShowModal(false);
                      setNewFieldType(null);
                    }} 
                    className="flex-1 bg-[#1A2747] text-white py-3 rounded-lg hover:bg-[#2A3E5C] transition-colors duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : isEnteringMcqOptions && newFieldType === 'mcq' ? (
              // MCQ Options input
              <div>
                <h2 className="text-2xl text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
                  Enter MCQ Options
                </h2>
                {mcqOptions.map((option, index) => (
                  <input
                    key={index}
                    type="text"
                    value={option}
                    onChange={(e) => updateMcqOption(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                    className="w-full p-3 bg-[#1A2747] text-white rounded-lg border border-[#2A3E5C] mb-4 focus:outline-none focus:border-[#DC3DF7]"
                  />
                ))}
                <div className="flex space-x-4 mb-4">
                  <button 
                    onClick={addMcqOption} 
                    className="w-full bg-[#527DE6] text-white py-3 rounded-lg hover:bg-[#6A90F5] transition-colors duration-300"
                  >
                    Add Another Option
                  </button>
                </div>
                <div className="flex space-x-4">
                  <button 
                    onClick={addField} 
                    className="flex-1 bg-[#DC3DF7] text-white py-3 rounded-lg hover:bg-[#E65BFA] transition-colors duration-300"
                  >
                    Create Field
                  </button>
                  <button 
                    onClick={() => {
                      setShowModal(false);
                      setNewFieldType(null);
                      setIsEnteringMcqOptions(false);
                    }} 
                    className="flex-1 bg-[#1A2747] text-white py-3 rounded-lg hover:bg-[#2A3E5C] transition-colors duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              // Original field input for non-MCQ types
              <div>
                <h2 className="text-2xl text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
                  Enter Question
                </h2>
                <input
                  type="text"
                  value={newFieldQuestion}
                  onChange={(e) => setNewFieldQuestion(e.target.value)}
                  placeholder={`Enter your ${newFieldType} question`}
                  className="w-full p-3 bg-[#1A2747] text-white rounded-lg border border-[#2A3E5C] mb-4 focus:outline-none focus:border-[#DC3DF7]"
                />
                <div className="flex space-x-4">
                  <button 
                    onClick={addField} 
                    className="flex-1 bg-[#DC3DF7] text-white py-3 rounded-lg hover:bg-[#E65BFA] transition-colors duration-300"
                  >
                    Add Field
                  </button>
                  <button 
                    onClick={() => {
                      setShowModal(false);
                      setNewFieldType(null);
                    }} 
                    className="flex-1 bg-[#1A2747] text-white py-3 rounded-lg hover:bg-[#2A3E5C] transition-colors duration-300"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Add Input Field Button */}
      <button 
        onClick={() => startAddField(null)} 
        className="bg-[#527DE6] text-white py-2 px-4 rounded flex items-center"
      >
        <Plus className="mr-2" /> Add Input Field
      </button>

      {/* Render Dynamic Fields */}
      {formFields.map(renderDynamicField)}

     

      {/* Overall Satisfaction Rating */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How would you rate your overall satisfaction?
        </h3>
        <div className="flex space-x-6 justify-center">
          {[1, 2, 3, 4, 5].map((rating) => (
            <button
              key={rating}
              onClick={() => updateFormData('overallRating', rating)}
              className="w-14 h-14 relative transition-all"
            >
              <img
                src={logo2}
                alt={`Rating ${rating}`}
                className="w-full h-full object-contain transition-opacity"
                style={{ opacity: formData.overallRating >= rating ? 1 : 0.3 }}
              />
            </button>
          ))}
        </div>
      </div>

      {/* Dynamic MCQ Questions */}
      {questions.map((q) => (
        <div key={q.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
          <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{q.question}</h3>
          <div className="space-y-4">
            {q.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleMCQSelection(q.id, index)}
                className={`w-full p-4 rounded-lg border-2 flex justify-between items-center bg-[#B7BDCA] ${
                  formData.selectedOptions[q.id] === index 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#B7BDCA] hover:border-[#DC3DF7]'
                }`}
              >
                <span className="text-[#0D182E]">{option}</span>
                <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                  formData.selectedOptions[q.id] === index 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#0D182E]'
                }`}>
                  {formData.selectedOptions[q.id] === index && (
                    <div className="w-full p-2.5 bg-[#DC3DF7] rounded-full border-white border-2" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      ))}

      {/* Recommendation Likelihood */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How likely are you to recommend our product/service?
        </h3>
        <div className="flex justify-between mb-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              onClick={() => updateFormData('recommendRating', rating)}
              className={`w-8 h-8 rounded flex items-center justify-center ${
                formData.recommendRating >= rating 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-white'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-300">
          <span>Not at all Likely</span>
          <span>Very Likely</span>
        </div>
      </div>

      {/* Additional Comments */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          Additional Comments
        </h3>
        <textarea
          value={formData.comment}
          onChange={(e) => updateFormData('comment', e.target.value)}
          placeholder="Please share any additional feedback..."
          className="w-full h-32 p-4 bg-[#1A2747] text-white rounded-lg border border-gray-700 focus:outline-none focus:border-blue-500 resize-none"
        />
      </div>

      {/* Attachments Section */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-white text-lg font-bold mb-6 font-['GeneralSansMedium']">
          Any Attachments To Show?
        </h3>
        <div 
          onClick={handleImageUpload}
          className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center cursor-pointer"
        >
          <div className="mb-4">
            <Upload className="w-12 h-12 text-white" />
          </div>
          <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
            Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
          </p>
          <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      </div>

      {/* Save Template Button */}
      <div className="text-center pt-4">
        <button 
          onClick={saveTemplate}
          className="bg-[#0D182E] text-white font-[GeneralSansRegular] py-3 px-8 rounded-lg transition-colors duration-300"
          style={{ fontFamily: 'GeneralSansMedium' }}
        >
          Save Template
        </button>
      </div>
    </div>
  );
};

export default ReviewSection;