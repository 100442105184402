import React, { useState, useRef, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import logo from "./assets/images/loogfinal.png"

const InvoiceGenerator = () => {
  const [invoiceData, setInvoiceData] = useState({
    companyInfo: {
      name: 'Review Pay',
      address: '123 Innovation Drive, Tech City, TC 12345',
      phone: '+1 (555) 123-4567',
      email: 'billing@reviewpay.com',
      taxId: 'TAX-ID: 98-7654321',
      acnNumber: 'ACN: 123 456 789'
    },
    customerInfo: {
      name: '',
      email: '',
      address: '',
      phone: ''
    },
    invoiceDetails: {
      number: `INV-${new Date().getFullYear()}-${Math.floor(Math.random() * 1000)}`,
      date: new Date().toISOString().split('T')[0],
      dueDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
    },
    items: [
      { description: '', percentage: 0, amount: '0' }
    ],
    notes: 'Thank you for your business. Please contact us for any questions.',
    bankDetails: {
      bankName: 'Global Bank',
      accountName: 'Review Pay',
      accountNumber: '1234-5678-9012-3456',
      swiftCode: 'GLOBXYZABC'
    }
  });

  const invoiceRef = useRef(null);

  const handleInputChange = (e, section, field) => {
    const { value } = e.target;
    setInvoiceData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const addLineItem = () => {
    setInvoiceData(prev => ({
      ...prev,
      items: [...prev.items, { description: '', quantity: 0, unitPrice: '0', tax: 0 }]
    }));
  };

  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const newItems = [...invoiceData.items];
    newItems[index][name] = value;
    setInvoiceData(prev => ({
      ...prev,
      items: newItems
    }));
  };

  const calculateSubtotal = () => {
    return invoiceData.items.reduce((total, item) => 
      total + Number(item.amount), 0
    );
  };

  const calculateTotal = () => {
    return invoiceData.items.reduce((total, item) => 
      total + (Number(item.amount) * (1 + Number(item.percentage)/100)), 0
    );
  };

 const generatePDF = async () => {
    const input = invoiceRef.current;
    
    try {
      const canvas = await html2canvas(input, { 
        scale: 2,
        useCORS: true,
        logging: false,
        allowTaint: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        windowHeight: 1122, // A4 height in pixels (297mm * 4)
        windowWidth: 794   // A4 width in pixels (210mm * 4)
      });
  
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });
  
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      
      // Always add the full page image, even if content is less
      pdf.addImage(
        canvas.toDataURL('image/png'), 
        'PNG', 
        0, 
        0, 
        imgWidth, 
        pageHeight, 
        '', 
        'FAST'
      );
  
      pdf.save(`invoice-${invoiceData.invoiceDetails.number}.pdf`);
  
    } catch (error) {
      console.error('PDF generation error:', error);
      alert('Failed to generate PDF. Please try again.');
    }
  };

  return (
    <div className="font-sans bg-gray-100 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Input Form */}
        <div className="p-6 bg-gray-50 border-b" style={{ fontFamily: 'GeneralSansRegular' }}>
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Invoice Generator</h2>
          
          {/* Company Information Input */}
          <div className="grid md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Company Name</label>
              <input
                type="text"
                value={invoiceData.companyInfo.name}
                onChange={(e) => handleInputChange(e, 'companyInfo', 'name')}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Company Email</label>
              <input
                type="email"
                value={invoiceData.companyInfo.email}
                onChange={(e) => handleInputChange(e, 'companyInfo', 'email')}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
          </div>

          {/* Customer Information Input */}
          <div className="grid md:grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Customer Name</label>
              <input
                type="text"
                value={invoiceData.customerInfo.name}
                onChange={(e) => handleInputChange(e, 'customerInfo', 'name')}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">Customer Email</label>
              <input
                type="email"
                value={invoiceData.customerInfo.email}
                onChange={(e) => handleInputChange(e, 'customerInfo', 'email')}
                className="w-full px-3 py-2 border rounded-md"
              />
            </div>
          </div>

          {/* Line Items Input */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Invoice Items</h3>
            {invoiceData.items.map((item, index) => (
            <div key={index} className="grid grid-cols-3 gap-2 mb-2">
              <input
                type="text"
                name="description"
                placeholder="Description"
                value={item.description}
                onChange={(e) => handleItemChange(index, e)}
                className="border px-2 py-1 rounded"
              />
              <input
                type="number"
                name="percentage"
                placeholder="Percentage"
                value={item.percentage}
                onChange={(e) => handleItemChange(index, e)}
                className="border px-2 py-1 rounded"
              />
              <input
                type="text"
                name="amount"
                placeholder="Amount"
                value={item.amount}
                onChange={(e) => handleItemChange(index, e)}
                className="border px-2 py-1 rounded"
              />
            </div>
          ))}
            <button 
              onClick={addLineItem}
              className="bg-blue-500 text-white px-4 py-2 rounded mt-2"
            >
              Add Line Item
            </button>
          </div>

          {/* Generate PDF Button */}
          <div className="mt-4">
            <button 
              onClick={generatePDF}
              className="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition"
            >
              Generate Professional Invoice
            </button>
          </div>
        </div>

        <div 
          ref={invoiceRef} 
          className="p-8 min-h-screen flex flex-col" 
          style={{ 
            fontFamily: 'GeneralSansRegular',
            backgroundColor: '#4D6F8F', 
            color: 'white' 
          }}
        >
          {/* Invoice Header */}
          <div className="flex justify-between items-start mb-8">
            <div className="flex items-center relative">
              <div 
                className="w-28 h-24 mr-4 flex items-center justify-center flex-col"
                style={{ borderRadius: '8px' }}
              >
                <img 
                  src={logo} 
                  alt="Company Logo" 
                  className=" w-20 h-20 object-contain"
                />
                <span 
                  style={{ 
                    fontFamily: 'VerminViva', 
                    color: 'white', 
                    marginTop: '5px' 
                    
                  }}
                >
                  Review Pay
                </span>
              </div>
              
              {/* Vertical Line */}
              <div 
                className="absolute left-32 h-full border-l-2 border-[#1F2229]" 
                style={{ height: '80%', top: 0 }}
              ></div>

              <div className="ml-8">
                <h1 className="text-3xl font-bold text-[#0D182E]" style={{ fontFamily: 'GeneralSansMedium' }}>INVOICE</h1>
              </div>
            </div>
            
            <div className="text-right">
              <div className="flex flex-col">
                <span className="font-[GeneralSansRegular]">Invoice Number <span className='font-[GeneralSansRegular] ml-3'>Invoice Date</span>  </span>
                <div>
                  <span className='text-[#0D182E] mr-7'>{invoiceData.invoiceDetails.number}</span>
                  <span className=" text-[#0D182E] ">{invoiceData.invoiceDetails.date}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Company and Customer Details */}
          <div 
            className="grid grid-cols-2 gap-4 mb-20 mt-20"
            style={{ fontFamily: 'GeneralSansRegular' }}
          >
            <div>
              <h3 className="mb-2 font-[Josefin]">RECIEPTIENT</h3>
              <p className='font-[GeneralSansRegular]  text-sm'>{invoiceData.customerInfo.name}</p>
              <p  className='font-[GeneralSansRegular]  text-sm'>{invoiceData.customerInfo.address}</p>
              <p  className='font-[GeneralSansRegular]  text-sm'>Phone: {invoiceData.customerInfo.phone}</p>
              <p  className='font-[GeneralSansRegular]  text-sm'>Email: {invoiceData.customerInfo.email}</p>
            </div>
            <div className="text-right">
              <h3 className="font-[Josefin] mb-2 text-[#1F2229]">REVIEW PAY</h3>
              <p className='text-[#1F2229]'>Tel: {invoiceData.companyInfo.phone}</p>
              <p className=' text-[#1F2229]'>{invoiceData.companyInfo.address}</p>
              <p className='text-[#1F2229]'>Email: {invoiceData.companyInfo.email}</p>
            </div>
          </div>

          {/* Invoice Items Table */}
          <table 
          className="w-full mb-8 rounded-lg border-0" 
          style={{ 
            backgroundColor: '#B7BDCA', 
            color: 'black'
          }} 
        >
          <thead>
            <tr>
              <th className="p-4 pl-4 text-left font-[Josefin]">Description</th>
              <th className="p-2 text-center font-[Josefin]">Percentage</th>
              <th className="p-2 text-right font-[Josefin]">Amount</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.items.map((item, index) => (
              <tr key={index}>
                <td className="p-2">{item.description}</td>
                <td className="p-2 text-center">{item.percentage}%</td>
                <td className="p-2 text-right">${Number(item.amount).toFixed(2)}</td>
              </tr>
            ))}
            
            {/* Horizontal Line before Subtotal */}
            <tr>
              <td colSpan="3" className="p-0 text-right">
                <div className="h-[2px] bg-black mr-2 ml-3 mt-3" style={{ width: '97%' }}></div>
              </td>
            </tr>
            
            {/* Subtotal and Total Rows */}
            <tr>
              <td colSpan="2" className="p-2 text-right font-[Josefin]">Subtotal</td>
              <td className="p-2 text-right">${calculateSubtotal().toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="3" className="p-0 text-right">
                <div className="h-[2px] bg-black ml-auto mr-3 mt-3" style={{ width: '35%' }}></div>
              </td>
            </tr>
            <tr>
              <td colSpan="2" className="p-2 text-right font-[Josefin] text-[#111F3B]">Total</td>
              <td className="p-2 text-right font-[Josefin] text-xl text-[#111F3B]">${calculateTotal().toFixed(2)}</td>
            </tr>
            
            {/* Horizontal Line for Total (Half-width and right-aligned) */}

          </tbody>
        </table>

          {/* Footer */}
          <div className="w-full h-[1px] bg-black mt-6"></div>
          <div 
            className="grid grid-cols-3 mt-8 text-sm"
            style={{ fontFamily: 'GeneralSansRegular' }}
          >
            <div>
              <p>{invoiceData.companyInfo.acnNumber}</p>
            </div>
            <div className="text-center">
              <p>{invoiceData.companyInfo.email}</p>
              <p>{invoiceData.companyInfo.phone}</p>
            </div>
            <div className="text-right">
              <p>{invoiceData.companyInfo.address}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceGenerator;