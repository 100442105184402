import React from "react";
import image from "./assets/images/zoho.png"
import image1 from "./assets/images/mailchimp.png"
import image3 from "./assets/images/salesforce.png"
import image4 from "./assets/images/monday.png"

const IntegrationsPage = () => {
  const integrations = [
    { name: "Zoho Mail", img: image },
    { name: "Mail Chimp", img: image1 },
    { name: "Salesforce ", img: image3 },
    { name: "Monday.com", img: image4 },
  ];

  return (
    <div className="min-h-screen pt-20 px-4 sm:px-6 lg:px-8 relative">
      {/* Page Header */}
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            INTEGRATIONS
          </h1>
        </div>
      </div>

      {/* Integrations Container */}
      <div className="max-w-full  p-6 sm:p-8 space-y-8">
        {integrations.map((integration, index) => (
          <div
            key={index}
            className="flex items-center space-x-6 bg-[#0D182E] rounded-2xl p-7"
          >
            {/* Integration Image */}
            <img
              src={integration.img}
              alt={integration.name}
              className="w-auto h-16 object-cover rounded-lg"
            />
            {/* Integration Name */}
            <h2 className="text-xl font-['GeneralSansBold'] text-white">
              {integration.name}
            </h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationsPage;
