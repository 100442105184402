import React, { useState } from 'react';
import { 
  ArrowLeft, 
  ArrowRight, 
  Upload,
  Pencil,
  PackageSearch,
  TextQuote,
  DollarSign,
  Plus
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import logo from "./assets/images/loogfinal.png"

const ProductManagement = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([{
    id: 1,
    image: null,
    name: '',
    description: '',
    price: ''
  }]);
  const [activeField, setActiveField] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const addProduct = () => {
    setProducts([...products, {
      id: products.length + 1,
      image: null,
      name: '',
      description: '',
      price: ''
    }]);
  };

  const handleInputChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index][field] = value;
    setProducts(updatedProducts);
  };

  const handleImageUpload = (index, files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const updatedProducts = [...products];
        updatedProducts[index].image = e.target.result;
        setProducts(updatedProducts);
      };
      reader.readAsDataURL(file);
      setIsDragging(false);
    }
  };

  const removeImage = (index) => {
    const updatedProducts = [...products];
    updatedProducts[index].image = null;
    setProducts(updatedProducts);
  };

  const handleDragOver = (e, productId) => {
    e.preventDefault();
    setIsDragging(productId);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    handleImageUpload(index, e.dataTransfer.files);
  };

  return (
    <div 
      className="w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-20">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold"
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize: 8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
        </div>
      </div>

      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-5xl border border-white/20 shadow-2xl mb-7">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">Add Product Details</h2>
        
        <div className="space-y-8">
          {products.map((product, index) => (
            <div 
              key={product.id} 
              className=" p-3 space-y-6"
            >
              {/* Image Upload */}
              <div 
                className={`border-2 border-dashed rounded-xl p-8 text-center cursor-pointer transition-all duration-200
                  ${isDragging === product.id 
                    ? 'border-white/50 bg-white/10 scale-[1.02]' 
                    : 'border-white/30 hover:border-white/50 bg-white/5'}`}
                onDragOver={(e) => handleDragOver(e, product.id)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, index)}
                onClick={() => document.getElementById(`productImage-${product.id}`).click()}
              >
                <input
                  id={`productImage-${product.id}`}
                  type="file"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(index, e.target.files)}
                />
                
                {product.image ? (
                  <div className="relative">
                    <img 
                      src={product.image} 
                      alt="Product" 
                      className="w-full h-64 object-cover rounded-lg"
                    />
                    <div className="absolute top-2 right-2 flex space-x-2">
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          document.getElementById(`productImage-${product.id}`).click();
                        }}
                        className="bg-white/20 p-2 rounded-full hover:bg-white/30"
                      >
                        <Pencil className="h-4 w-4 text-white" />
                      </button>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          removeImage(index);
                        }}
                        className="bg-red-500/70 p-2 rounded-full hover:bg-red-500"
                      >
                        ×
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="bg-blue-50 rounded-full w-16 h-16 flex items-center justify-center mx-auto mb-4">
                      <Upload className="h-8 w-8 text-blue-950" />
                    </div>
                    <p className="text-sm font-['GeneralSansMedium'] text-gray-300 mb-1">
                      Click to upload or drag and drop
                    </p>
                    <p className="text-xs text-gray-500">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </div>
                )}
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Product Name */}
                <div className="relative">
                  <label 
                    htmlFor={`productName-${product.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Product Name
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <PackageSearch className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`productName-${product.id}`}
                    type="text"
                    value={product.name}
                    onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                    placeholder="Enter product name"
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                      text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                      focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                      transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>

                {/* Price */}
                <div className="relative">
                  <label 
                    htmlFor={`productPrice-${product.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Price
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <DollarSign className="h-5 w-5 text-white/50 mt-6" />
                  </div>
                  <input
                    id={`productPrice-${product.id}`}
                    type="number"
                    value={product.price}
                    onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                    placeholder="Enter price"
                    className="w-full px-4 py-3 pl-10 bg-[#647894] border border-white/10 rounded-lg 
                      text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                      focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                      transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>

                {/* Description */}
                <div className="md:col-span-2 relative">
                  <label 
                    htmlFor={`productDescription-${product.id}`} 
                    className="block text-sm text-white/80 mb-2 font-['GeneralSansMedium']"
                  >
                    Description
                  </label>
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none z-10">
                    <TextQuote className="h-5 w-5 text-white/50 -mt-7" />
                  </div>
                  <textarea
                    id={`productDescription-${product.id}`}
                    value={product.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                    placeholder="Enter product description"
                    className="w-full px-4 py-3 pl-10 min-h-[100px] bg-[#647894] border border-white/10 rounded-lg 
                      text-white placeholder-gray-300 focus:outline-none focus:ring-2 
                      focus:ring-white/30 focus:border-transparent backdrop-blur-sm
                      transition-all duration-300 placeholder:font-['GeneralSansRegular'] appearance-none"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Add Another Product Button */}
        <button
          onClick={addProduct}
          className="mt-6 w-full flex items-center justify-center px-6 py-3 
                     bg-white/10 border border-white/20 text-white rounded-lg 
                     font-['GeneralSansMedium'] hover:bg-white/20 transition-all duration-200"
        >
          <Plus className="w-5 h-5 mr-2" />
          Add Another Product
        </button>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-12 pt-6 border-t border-white/20">
          <button
            type="button"
            onClick={() => navigate('/BusinessDashboard')}
            className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
                       font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-200"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <button
            type="submit"
            onClick={() => navigate('/BusinessDashboard')}
            className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
                       text-white rounded-lg font-['GeneralSansMedium'] hover:opacity-90 
                       transition-all duration-200 shadow-lg hover:shadow-xl 
                       hover:scale-[1.02]"
          >
            Next Step
            <ArrowRight className="w-4 h-4 ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductManagement;