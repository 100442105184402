import React from 'react';
import { useNavigate } from 'react-router-dom';

// Import icons (replace with your actual icon paths)
import AppleIcon from './assets/images/apple.png';
import GoogleIcon from './assets/images/google.png';
import FacebookIcon from './assets/images/facebook.png';
import EmailIcon from './assets/images/mail.png';
import LogoIcon from './assets/images/loogfinal.png';


const MobileAppFrontend = () => {
  const navigate = useNavigate();

  const handleEmailPress = () => {
    navigate('/signin');
  };

  return (
    <div 
      className="relative w-full min-h-screen flex flex-col items-center justify-center px-4"
      style={{ 
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      {/* Content Container */}
      <div className="relative z-10 flex flex-col items-center justify-center">
        {/* App Logo */}
        <img 
          src={LogoIcon} 
          alt="App Logo" 
          className="w-[114px] h-[90px] mb-5 mt-5"
        />

        {/* Title */}
        <h1 className="text-4xl font-bold text-white text-center mb-24 font-['GeneralSansBold']">
          Get started, share review, refer & earn.
        </h1>

        {/* Button Container */}
        <div className="w-full space-y-4 px-4 -mt-5">
          {/* Apple Button */}
          <button className="w-full bg-white border border-black rounded-full py-4 flex items-center justify-center font-['GeneralSansMedium']">
            <img src={AppleIcon} alt="Apple" className="w-6 h-6 mr-3" />
            <span className="text-[#1A2E56]">Continue with Apple</span>
          </button>

          {/* Google Button */}
          <button className="w-full bg-white border border-black rounded-full py-4 flex items-center justify-center font-['GeneralSansMedium']">
            <img src={GoogleIcon} alt="Google" className="w-6 h-6 mr-3" />
            <span className="text-[#1A2E56]">Continue with Google</span>
          </button>

          {/* Facebook Button */}
          <button className="w-full bg-white border border-black rounded-full py-4 flex items-center justify-center font-['GeneralSansMedium']">
            <img src={FacebookIcon} alt="Facebook" className="w-6 h-6 mr-3" />
            <span className="text-[#1A2E56]">Continue with Facebook</span>
          </button>

          {/* Email Button */}
          <button 
            onClick={handleEmailPress}
            className="w-full bg-white border border-black rounded-full py-4 flex items-center justify-center font-['GeneralSansMedium']"
          >
            <img src={EmailIcon} alt="Email" className="w-6 h-6 mr-3" />
            <span className="text-[#1A2E56]">Continue with Email</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileAppFrontend;