import React, { useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer ,
  Area,AreaChart
} from 'recharts';
import { 
  Mail, 
  Phone, 
  MapPin, 
  Globe, 
  Instagram, 
  Facebook, 
  Utensils, 
  Handshake,
  Clock
} from 'lucide-react';
import video1 from "./assets/images/reviewvideo.mp4";
import logo1 from "./assets/images/avatar2.png";

import { useNavigate } from 'react-router-dom';
import reviewImage from "./assets/images/bimage.png";
import logo from "./assets/images/loogfinal.png"; 
import authink from "./assets/images/logoauthentic.png";
import leftImage from "./assets/images/hand_mobile.png";
import rightImage from "./assets/images/referral_image.png";
import logoauth from "./assets/images/logoauthentic.png"
import reviewsIcon from "./assets/images/Existing Review.png";
import profile from "./assets/images/profile.png";

// Import product and team images 
import product1 from "./assets/images/noburestaurent.png";
import product2 from "./assets/images/noburestaurent.png";
import product3 from "./assets/images/noburestaurent.png";
import team1 from "./assets/images/teammember.jpeg";
import team2 from "./assets/images/teammember.jpeg";
import team3 from "./assets/images/teammember.jpeg";

const generateData = () => {
  const data = [];
  const startDate = new Date('2012-01-01');
  const endDate = new Date('2013-02-27');
  
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 3)) {
    const baseValue = 20 + (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7);
    const randomFactor = Math.random() * 2 - 1;
    data.push({
      date: date.getTime(),
      value: parseFloat((baseValue + randomFactor).toFixed(2))
    });
  }
  return data;
};
const sampleData = generateData();


const ReviewSection = () => {
  const navigate = useNavigate();
  const [selectedPeriod, setSelectedPeriod] = useState('12 months');
  const [activeTab, setActiveTab] = useState('products');
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
  const [timeRange, setTimeRange] = useState('1Y');
  
  const filterData = (range) => {
    const now = new Date('2013-02-27').getTime();
    let startDate;
    
    switch(range) {
      case '1 Week':
        startDate = new Date('2013-02-20').getTime();
        break;
      case '1 Month':
        startDate = new Date('2013-01-27').getTime();
        break;
      case '1 Year':
        startDate = new Date('2012-02-27').getTime();
        break;
      default:
        startDate = new Date('2012-02-27').getTime();
    }
    
    return sampleData.filter(item => item.date >= startDate && item.date <= now);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTooltipDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

 
  const chartData = {
    '12 months': [
      { month: 'Jan', reviews: 40, referrals: 24 },
      { month: 'Feb', reviews: 35, referrals: 28 },
      { month: 'Mar', reviews: 45, referrals: 32 },
      { month: 'Apr', reviews: 50, referrals: 36 },
      { month: 'May', reviews: 42, referrals: 30 },
      { month: 'Jun', reviews: 48, referrals: 35 }
    ],
    '30 days': [
      { month: 'Week 1', reviews: 15, referrals: 8 },
      { month: 'Week 2', reviews: 18, referrals: 10 },
      { month: 'Week 3', reviews: 22, referrals: 12 },
      { month: 'Week 4', reviews: 25, referrals: 15 }
    ],
    '7 days': [
      { month: 'Day 1', reviews: 5, referrals: 3 },
      { month: 'Day 2', reviews: 6, referrals: 4 },
      { month: 'Day 3', reviews: 7, referrals: 5 },
      { month: 'Day 4', reviews: 8, referrals: 6 },
      { month: 'Day 5', reviews: 9, referrals: 7 },
      { month: 'Day 6', reviews: 10, referrals: 8 },
      { month: 'Day 7', reviews: 11, referrals: 9 }
    ]
  };

  const productsData = [
    {
      id: 1,
      image: product1,
      name: "Premium Tattoo Ink Set",
      description: "High-quality, vibrant ink set for professional tattoo artists.",
      price: "$129.99"
    },
    {
      id: 2,
      image: product2,
      name: "Artist Pro Tattoo Machine",
      description: "Precision-engineered rotary tattoo machine for smooth lines.",
      price: "$349.99"
    },
    {
      id: 3,
      image: product3,
      name: "Healing Tattoo Care Kit",
      description: "Complete aftercare kit for optimal tattoo healing and maintenance.",
      price: "$49.99"
    }
  ];
  const reviewsData = [
    {
      id: 1,
      name: 'Floyd Miles',
      subtitle: '147 Reviews 23 Referrals - Brand Ambassador',
      date: '1 Month Ago',
      customerExperience: '99/100',
      review: 'First of all I want to start off by Thanking the team at Authentink as everyone was always super friendly and helpful with our enquiries and booking. However a HUGE Thank you to Mertim, who not only did an absolutely amazing job with my tattoo but I genuinely walked out of the studio feeling like I had made a great friend.',
      performanceBars: [
        { label: "Quality", value: "9", percentage: 90 },
        { label: "Service", value: "10", percentage: 100 },
        { label: "Cleanliness", value: "9.5", percentage: 95 },
        { label: "Value", value: "8.5", percentage: 85 },
        { label: "Communication", value: "9.8", percentage: 98 }
      ]
    },
    {
      id: 2,
      name: 'Sarah Johnson',
      subtitle: '82 Reviews 15 Referrals - Bronze Ambassador',
      date: '2 Months Ago',
      customerExperience: '95/100',
      review: 'An incredible experience from start to finish. The attention to detail and professionalism was outstanding. I would highly recommend this service to anyone looking for top-quality work.',
      performanceBars: [
        { label: "Quality", value: "9", percentage: 90 },
        { label: "Service", value: "9.5", percentage: 95 },
        { label: "Cleanliness", value: "9", percentage: 90 },
        { label: "Value", value: "8", percentage: 80 },
        { label: "Communication", value: "9.5", percentage: 95 }
      ]
    },
    {
      id: 3,
      name: 'Michael Chen',
      subtitle: '65 Reviews 10 Referrals - Silver Ambassador',
      date: '3 Months Ago',
      customerExperience: '92/100',
      review: 'A fantastic service that exceeded my expectations. The team was knowledgeable, friendly, and truly passionate about what they do. Im looking forward to working with them again.',
      performanceBars: [
        { label: "Quality", value: "8.5", percentage: 85 },
        { label: "Service", value: "9", percentage: 90 },
        { label: "Cleanliness", value: "9", percentage: 90 },
        { label: "Value", value: "8.5", percentage: 85 },
        { label: "Communication", value: "9", percentage: 90 }
      ]
    }
  ];

  // Team Members Data
  const teamMembersData = [
    {
      id: 1,
      image: team1,
      name: "John Smith",
      role: "Lead Tattoo Artist",
      description: "15 years of experience in realistic and portrait tattoos."
    },
    {
      id: 2,
      image: team2,
      name: "Emma Rodriguez",
      role: "Color Specialist",
      description: "Expert in vibrant color work and watercolor techniques."
    },
    {
      id: 3,
      image: team3,
      name: "Mike Chen",
      role: "Traditional Art Master",
      description: "Specializing in traditional and neo-traditional tattoo styles."
    }
  ];

  // Card Component for both Products and Team
  const Card = ({ image, name, description, price, role }) => (
    <div className="bg-[#0D182E] rounded-xl p-6 space-y-4 transform transition-all duration-300 hover:scale-105">
      <div className="h-64 overflow-hidden rounded-lg">
        <img 
          src={image} 
          alt={name} 
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      <div className="text-white text-center">
        <h3 className="text-2xl font-bold mb-2">{name}</h3>
        {role && <p className="text-[#65D4B0] mb-2">{role}</p>}
        <p className="text-gray-300 mb-4">{description}</p>
        {price && (
          <div className="flex justify-center items-center">
            <span className="text-[#65D4B0] text-xl font-bold">{price}</span>
            <button className="ml-4 bg-[#B7BDCA] px-4 py-2 rounded-lg text-black hover:bg-opacity-80 transition-all">
              Buy Now
            </button>
          </div>
        )}
      </div>
    </div>
  );
  const PerformanceBar = ({ label, value, percentage }) => (
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4 w-full">
      <div className="w-full sm:w-1/4 mb-2 sm:mb-0">
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular']">{label}</span>
      </div>
      <div className="flex items-center space-x-4 w-full sm:w-3/4">
        <div className="flex-grow bg-gray-700 h-3 rounded-full">
          <div 
            className="bg-[#65D4B0] h-full rounded-full transition-all duration-300" 
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
        <span className="text-white text-base sm:text-lg font-['GeneralSansRegular'] min-w-[60px] text-right">
          {value}/10
        </span>
      </div>
    </div>
  );

  
  return (
    <div className="w-full bg-[#4D6F8F] p-4 sm:p-8 space-y-4 sm:space-y-8 mt-20" style={{fontFamily: 'GeneralSansRegular'}}>

      
      <div className="border-2 sm:border-4 border-white rounded-xl overflow-hidden appearance-none">
        <div className="relative w-full h-48 sm:h-96 appearance-none">
        <video 
  className="w-full h-full object-cover appearance-none"
  autoPlay
  muted 
  playsInline
  loop
>
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          {/* Logo and Text Container */}
          <div className="absolute bottom-4 sm:bottom-8 left-4 sm:left-8 flex items-center space-x-2 sm:space-x-4">
            <img 
              src={logo1} 
              alt="Company Logo" 
              className="h-10 sm:h-16 w-auto"
            />
            
            <div className="text-white w-32 sm:w-44">
              <h2 className="text-lg sm:text-2xl font-bold">Crown Casino Melborne</h2>
            </div>
          </div>
        </div>
      </div>
      
      {/* QR Code Containers */}
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-4 sm:gap-8 mt-4 sm:mt-8">
        {/* Left QR Code Container */}
        <div className="flex flex-col items-center space-y-2 sm:space-y-4">
          <div className="w-40 h-40 sm:w-60 sm:h-60 bg-[#B7BDCA] rounded-3xl flex items-center justify-center">
            <QRCode
              value="https://www.instagram.com/"
              size={120} // Adjusted for mobile
              logoImage={logo}
              logoWidth={45} // Reduced for mobile
              logoHeight={35} // Reduced for mobile
              logoPadding={5}
              logoPaddingStyle='square'
              logoOpacity={1}
              quietZone={8}
              fgColor="black"
              bgColor="#B7BDCA"
            />
          </div>
          <h2 className="text-xl sm:text-3xl text-white font-[VarinoRegular]">Review $5</h2>
        </div>

        {/* Right QR Code Container */}
        <div className="flex flex-col items-center space-y-2 sm:space-y-4">
          <div className="w-40 h-40 sm:w-60 sm:h-60 bg-[#0D182E] rounded-3xl flex items-center justify-center">
            <QRCode
              value="https://referral10.com"
              size={120} // Adjusted for mobile
              logoImage={logo}
              logoWidth={45} // Reduced for mobile
              logoHeight={35} // Reduced for mobile
              logoPadding={5}
              logoPaddingStyle='square'
              logoOpacity={1}
              quietZone={8}
              fgColor="#ffffff"
              bgColor="#0D182E"
            />
          </div>
          <h2 className="text-xl sm:text-3xl text-white font-[VarinoRegular]">Referral 9%</h2>
        </div>
      </div>

      {/* Centered Paragraph */}
      <div className="text-center my-4 sm:my-8 px-4 sm:px-16">
        <p className="text-white text-base sm:text-xl">
        From acclaimed restaurants and award-winning hotels, to world-class table games and endless entertainment, Crown Rewards helps you to discover the world of Crown
        </p>
      </div>

      {/* Compact Full Width Container */}
      <div className="w-full bg-[#0D182E] py-12 px-4 rounded-2xl border-8 border-emerald-400">
        <div className="max-w-4xl mx-auto flex justify-between items-center">
          <div className="w-1/2 pr-6">
            <img 
              src={leftImage} 
              alt="Left Image" 
              className="w-full h-auto mb-4"
            />
            <p className="text-left text-[#DC3DF7]  font-[VarinoRegular] text-xl sm:text-2xl md:text-3xl">
            Total Reviews
            </p>
          </div>

          {/* Right Section */}
          <div className="w-1/2 pl-6">
            <img 
              src={rightImage} 
              alt="Right Image" 
              className="w-full h-auto mb-10 "
            />
            <p className="text-right text-[#DC3DF7] font-[VarinoRegular] text-xl sm:text-2xl md:text-3xl">
            Total Referrals
            </p>
          </div>
        </div>

        

        {/* Full Width Line */}
        <div className="w-full border-t border-white/30 my-6 max-w-4xl mx-auto"></div>

        {/* Text on Left and Right */}
        <div className="flex justify-between text-white max-w-4xl mx-auto">
          <p className="text-left text-3xl text-[#65D4B0] font-[VarinoRegular]">2001</p>
          <p className="text-right text-3xl text-[#65D4B0] font-[VarinoRegular]">1984</p>
        </div>
      </div>
      <div className="w-full bg-[#0D182E] py-12 px-4 rounded-2xl border-8 border-emerald-400">
      {/* Business Name and Logo Section */}
      <div className="flex items-center justify-center mb-8 space-x-6">
        <img 
          src={logo1}  // Replace with your actual logo path
          alt="Business Logo" 
          className="h-20 w-auto"
        />
        <h1 className="text-white text-4xl font-bold">Crown Casino Melborne</h1>
      </div>

      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Work with Us */}
        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Handshake color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Work with Us</h3>
            <p className="text-[#B7BDCA] text-base">Join our dynamic team!</p>
          </div>
        </div>

        {/* Working Hours */}
        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Clock color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Working Hours</h3>
            <p className="text-[#B7BDCA] text-base">
              Mon-Fri: 9:00 AM - 5:00 PM<br />
              Sat: 10:00 AM - 2:00 PM<br />
              Sun: Closed
            </p>
          </div>
        </div>

        {/* Existing sections remain the same */}
        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Utensils color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Category</h3>
            <p className="text-[#B7BDCA] text-base">Restaurant & Entertainment</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Phone color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Phone</h3>
            <p className="text-[#B7BDCA] text-base">+61 3 9292 8888</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Globe color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Website</h3>
            <p className="text-[#B7BDCA] text-base">www.crownmelbourne.com.au</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Instagram color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Instagram</h3>
            <p className="text-[#B7BDCA] text-base">@crownresorts</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Facebook color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Facebook</h3>
            <p className="text-[#B7BDCA] text-base">Crown Melbourne</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl">
          <Mail color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Email</h3>
            <p className="text-[#B7BDCA] text-base">contact@crownmelbourne.com.au</p>
          </div>
        </div>

        <div className="flex items-center space-x-4 bg-[#0D182E] p-6 rounded-xl col-span-full">
          <MapPin color="#65D4B0" size={40} />
          <div>
            <h3 className="text-white text-xl font-bold">Location</h3>
            <p className="text-[#B7BDCA] text-base">
              Crown Towers, 8 Whiteman St, Southbank VIC 3006, Australia
            </p>
          </div>
        </div>
      </div>
    </div>
      <div className="w-full bg-[#0D182E] py-12 px-8 rounded-2xl border-8 border-emerald-500">
        <div className="max-w-5xl mx-auto flex flex-col lg:flex-row">
          {/* Chart Container */}
          <div className="w-full lg:w-2/3 pr-0 lg:pr-8">
            <h2 className="text-white text-3xl font-[GeneralSansSemibold] mb-6 text-center lg:text-left">
              Business Performance Insights
            </h2>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={chartData[selectedPeriod]}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid 
                  horizontal={true} 
                  vertical={false} 
                  stroke="rgba(255,255,255,0.1)" 
                />
                <XAxis 
                  dataKey="month" 
                  tick={{ fill: 'white' }} 
                  axisLine={{ stroke: 'white' }}
                />
                <YAxis 
                  tick={{ fill: 'white' }} 
                  axisLine={{ stroke: 'white' }}
                />
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#0D182E', 
                    color: 'white',
                    border: '1px solid white'
                  }} 
                />
                <Legend 
                  wrapperStyle={{ color: 'white' }} 
                  iconType="circle"
                />
                <Bar 
                  dataKey="reviews" 
                  stackId="a" 
                  fill="#DC3DF7" 
                  name="Reviews" 
                  barSize={35} // Reduced bar width
                />
                <Bar 
                  dataKey="referrals" 
                  stackId="a" 
                  fill="#65D4B0" 
                  name="Referrals" 
                  barSize={35} // Reduced bar width
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* Period Selection Tabs */}
          <div className="w-full lg:w-1/3 flex flex-col space-y-4 mt-8 lg:mt-0">
            {['12 months', '30 days', '7 days'].map((period) => (
              <button
                key={period}
                onClick={() => setSelectedPeriod(period)}
                className={`w-full py-3 text-white text-lg rounded-lg transition-all duration-300 ${
                  selectedPeriod === period 
                    ? 'bg-[#DC3DF7] font-bold' 
                    : 'bg-[#0D182E] hover:bg-transparent'
                }`}
              >
                {period}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['GeneralSansSemibold'] text-slate-100 mb-4">Customer Satisfaction</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="mb-4 flex gap-2">
              {['1 Year', '1 Month', '1 Week'].map((range) => (
                <button
                  key={range}
                  onClick={() => setTimeRange(range)}
                  className={`px-3 py-1 rounded font-['GeneralSansMedium'] ${
                    timeRange === range
                      ? 'bg-emerald-400 text-slate-900'
                      : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
                  }`}
                >
                  {range}
                </button>
              ))}
            </div>
            
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={filterData(timeRange)}
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                      <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <CartesianGrid 
                    horizontal={true}
                    vertical={false}
                    stroke="#1E2A44"
                  />
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatDate}
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    stroke="#4B5563"
                    tick={{ fontFamily: 'GeneralSansRegular' }}
                  />
                  <YAxis 
                    stroke="#4B5563"
                    tick={{ fontFamily: 'GeneralSansRegular' }}
                  />
                  <Tooltip
                    labelFormatter={formatTooltipDate}
                    formatter={(value) => [`${value}`, 'Satifaction Level']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    labelStyle={{ color: '#9CA3AF' }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#65D4B0"
                    fillOpacity={1}
                    fill="url(#colorValue)"
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
      <section className="mb-8 bg-[#0D182E] rounded-lg shadow-md p-4 sm:p-6">
        <div className="flex items-center mb-4">
          <img src={reviewsIcon} alt="Reviews" className="w-8 h-8 mr-4" />
          <h2 className="text-xl sm:text-2xl  font-[VarinoRegular] text-white">Existing Reviews</h2>

        </div>
       
      <div className="text-center m-2">
        <button 
          className="px-4 py-3 rounded-lg font-['GeneralSansBold'] text-white text-base mr-5"
          style={{ backgroundColor: '#B7BDCA',color :"#0D182E" }}
        >
          Respond    
        </button>
        <button 
          className="px-4 py-3 rounded-lg font-['GeneralSansBold'] text-white text-base mr-5"
          style={{ backgroundColor: '#B7BDCA',color :"#0D182E" }}
        >
          Like    
        </button>
        <button 
          className="px-4 py-3 rounded-lg font-['GeneralSansBold'] text-white text-base"
          style={{ backgroundColor: '#B7BDCA',color :"#0D182E" }}
        >
          Report    
        </button>
      </div>
        

        {/* Reviews Carousel Container */}
        <div className="relative w-full overflow-hidden">
          {/* Navigation Buttons */}
          {currentReviewIndex > 0 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex - 1)}
              className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &lt;
            </button>
          )}
          {currentReviewIndex < reviewsData.length - 1 && (
            <button 
              onClick={() => setCurrentReviewIndex(currentReviewIndex + 1)}
              className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 bg-[#527DE6] text-white p-2 rounded-full"
            >
              &gt;
            </button>
          )}

          {/* Reviews Content */}
          <div 
            className="w-full transition-transform duration-300 ease-in-out"
            style={{ 
              transform: `translateX(-${currentReviewIndex * 100}%)`,
            }}
          >
            <div className="flex">
              {reviewsData.map((review) => (
                <div 
                  key={review.id} 
                  className="w-full flex-shrink-0 bg-[#1a2942] rounded-lg shadow-md p-6"
                >
                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
                    <div className="flex items-start space-x-4 mb-2 sm:mb-0">
                      <div className="w-12 h-12 rounded-full overflow-hidden flex-shrink-0">
                        <img
                          src={profile}
                          alt="Profile"
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div>
                        <h3 className="font-['GeneralSansBold'] text-white text-lg">{review.name}</h3>
                        <p className="font-['GeneralSansRegular'] text-gray-300 text-sm">
                          {review.subtitle}
                        </p>
                      </div>
                    </div>
                    <span className="font-['GeneralSansRegular'] text-gray-300 text-sm">{review.date}</span>
                  </div>

                  <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-6 space-y-4 sm:space-y-0">
                    <div className="flex space-x-2">
                      {[1, 2, 3, 4, 5].map((item) => (
                        <img
                          key={item}
                          src={logo}
                          alt="Rating"
                          className="w-6 sm:w-8 h-4 sm:h-6"
                        />
                      ))}
                    </div>
                    <img
                      src={logoauth}
                      alt="Authentic"
                      className="w-28 sm:w-36 h-8 sm:h-10"
                    />
                  </div>

                  <div className="mb-4">
                    <h4 className="text-white font-['GeneralSansBold'] text-base sm:text-lg mb-2">
                      Customer Experience: {review.customerExperience}
                    </h4>
                  </div>

                  <p className="font-['GeneralSansRegular'] text-gray-300 mb-6 text-sm sm:text-base">
                    {review.review}
                  </p>

                  <div className="space-y-2">
                    {review.performanceBars.map((bar, index) => (
                      <PerformanceBar 
                        key={index} 
                        label={bar.label} 
                        value={bar.value} 
                        percentage={bar.percentage} 
                      />
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center mt-4 space-x-2">
            {reviewsData.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentReviewIndex(index)}
                className={`
                  w-3 h-3 rounded-full 
                  ${currentReviewIndex === index ? 'bg-[#527DE6]' : 'bg-gray-300'}
                `}
              />
            ))}
          </div>
        </div>
      </section>

      <div className="w-full bg-[#0D182E] py-12 px-8 rounded-2xl border-8 border-[#B7BDCA]">
        <div className="max-w-5xl mx-auto">
          {/* Tab Navigation */}
          <div className="flex justify-center mb-8">
            <button
              onClick={() => setActiveTab('products')}
              className={`px-8 py-3 mx-2 text-xl rounded-lg transition-all duration-300 ${
                activeTab === 'products' 
                  ? 'bg-[#B7BDCA] text-black ' 
                  : 'bg-transparent text-white border border-white/30 hover:bg-white/10'
              }`}
            >
              Products
            </button>
            <button
              onClick={() => setActiveTab('team')}
              className={`px-8 py-3 mx-2 text-xl rounded-lg transition-all duration-300 ${
                activeTab === 'team' 
                  ? 'bg-[#B7BDCA] text-black ' 
                  : 'bg-transparent text-white border border-white/30 hover:bg-white/10'
              }`}
            >
              Our Team
            </button>
          </div>

          {/* Content Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {(activeTab === 'products' ? productsData : teamMembersData).map((item) => (
              <Card 
                key={item.id}
                image={item.image}
                name={item.name}
                description={item.description}
                price={item.price}
                role={item.role}
              />
            ))}
          </div>

          {/* View All Button */}
          <div className="flex justify-center mt-8">
            <button 
              className="bg-[#B7BDCA] text-black px-10 py-3 rounded-lg text-lg hover:bg-opacity-80 transition-all font-[GeneralSansMedium]"
              onClick={() => {
                // Navigate to specific pages based on active tab
                if (activeTab === 'products') {
                  navigate('products');
                } else {
                  navigate('team');
                }
              }}
            >
              View All {activeTab === 'products' ? 'Products' : 'Team Members'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;