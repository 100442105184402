import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import team1 from "./assets/images/teammember.jpeg";
import team2 from "./assets/images/teammember2.png"
import team3 from "./assets/images/teammember3.jpeg"
import team4 from "./assets/images/teammember1.jpeg"


const TeamPage = () => {
    const navigate = useNavigate();
  const [selectedMember, setSelectedMember] = useState(null);
  
  const teamMembersData = [
    {
      id: 1,
      image: team1,
      name: "John Smith",
      role: "Lead Tattoo Artist",
      description: "15 years of experience in realistic and portrait tattoos.",
      fullBio: "John Smith is a renowned tattoo artist with over 15 years of experience in the industry. Specializing in hyper-realistic portrait tattoos, John has worked with numerous high-profile clients and has been featured in multiple international tattoo magazines. His meticulous attention to detail and ability to capture intricate emotions in his work sets him apart in the tattoo community.",
      specialties: [
        "Realistic Portraits",
        "Black and Gray Techniques",
        "Detailed Line Work",
        "Photorealistic Art",
        "Portraiture"
      ],
      awards: [
        "Best Realistic Tattoo - National Tattoo Convention 2022",
        "Master Artist Recognition - International Ink Awards 2021"
      ],
      socialMedia: {
        instagram: "@johnsmith_tattoo",
        portfolio: "johnsmithart.com",
        facebook: "John Smith Tattoo Art"
      }
    },
    {
      id: 2,
      image: team2,
      name: "Emma Rodriguez",
      role: "Color Specialist",
      description: "Expert in vibrant color work and watercolor techniques.",
      fullBio: "Emma Rodriguez is a pioneering color tattoo artist known for her revolutionary watercolor and vibrant color techniques. With a background in fine arts, Emma brings a unique artistic perspective to tattooing. She has developed proprietary color blending methods that have inspired artists worldwide and conducted multiple international workshops on advanced color tattooing.",
      specialties: [
        "Watercolor Tattoos",
        "Vibrant Color Blending",
        "Custom Color Designs",
        "Abstract Art Tattoos",
        "Color Theory Application"
      ],
      awards: [
        "Innovation in Color Tattoo - Global Ink Expo 2023",
        "Rising Star Award - Tattoo Artist Magazine 2022"
      ],
      socialMedia: {
        instagram: "@emma_colormagic",
        portfolio: "emmarodriguez.art",
        facebook: "Emma Rodriguez Tattoo Art"
      }
    },
    {
      id: 3,
      image: team1,
      name: "Mike Chen",
      role: "Traditional Art Master",
      description: "Specializing in traditional and neo-traditional tattoo styles.",
      fullBio: "Mike Chen is a master of traditional tattoo art, bringing decades of cultural and artistic expertise to every piece. With a deep respect for tattoo history and traditional techniques, Mike has studied various cultural tattooing methods from around the world. He is not just an artist, but a storyteller who believes each tattoo carries a profound narrative.",
      specialties: [
        "Traditional Tattoos",
        "Neo-Traditional Designs",
        "Cultural Tattoo Art",
        "Japanese Traditional Techniques",
        "Tribal Inspired Designs"
      ],
      awards: [
        "Master of Traditional Tattooing - World Tattoo Conference 2022",
        "Cultural Preservation in Art - Heritage Awards 2021"
      ],
      socialMedia: {
        instagram: "@mikechen_traditional",
        portfolio: "mikechenart.com",
        facebook: "Mike Chen Traditional Tattoo"
      }
    },
    {
        id: 4,
        image: team3,
        name: "Mike Chen",
        role: "Traditional Art Master",
        description: "Specializing in traditional and neo-traditional tattoo styles.",
        fullBio: "Mike Chen is a master of traditional tattoo art, bringing decades of cultural and artistic expertise to every piece. With a deep respect for tattoo history and traditional techniques, Mike has studied various cultural tattooing methods from around the world. He is not just an artist, but a storyteller who believes each tattoo carries a profound narrative.",
        specialties: [
          "Traditional Tattoos",
          "Neo-Traditional Designs",
          "Cultural Tattoo Art",
          "Japanese Traditional Techniques",
          "Tribal Inspired Designs"
        ],
        awards: [
          "Master of Traditional Tattooing - World Tattoo Conference 2022",
          "Cultural Preservation in Art - Heritage Awards 2021"
        ],
        socialMedia: {
          instagram: "@mikechen_traditional",
          portfolio: "mikechenart.com",
          facebook: "Mike Chen Traditional Tattoo"
        }
      }
      ,
      {
        id: 5,
        image: team4,
        name: "Mike Chen",
        role: "Traditional Art Master",
        description: "Specializing in traditional and neo-traditional tattoo styles.",
        fullBio: "Mike Chen is a master of traditional tattoo art, bringing decades of cultural and artistic expertise to every piece. With a deep respect for tattoo history and traditional techniques, Mike has studied various cultural tattooing methods from around the world. He is not just an artist, but a storyteller who believes each tattoo carries a profound narrative.",
        specialties: [
          "Traditional Tattoos",
          "Neo-Traditional Designs",
          "Cultural Tattoo Art",
          "Japanese Traditional Techniques",
          "Tribal Inspired Designs"
        ],
        awards: [
          "Master of Traditional Tattooing - World Tattoo Conference 2022",
          "Cultural Preservation in Art - Heritage Awards 2021"
        ],
        socialMedia: {
          instagram: "@mikechen_traditional",
          portfolio: "mikechenart.com",
          facebook: "Mike Chen Traditional Tattoo"
        }
      },
      {
        id: 6,
        image: team2,
        name: "Mike Chen",
        role: "Traditional Art Master",
        description: "Specializing in traditional and neo-traditional tattoo styles.",
        fullBio: "Mike Chen is a master of traditional tattoo art, bringing decades of cultural and artistic expertise to every piece. With a deep respect for tattoo history and traditional techniques, Mike has studied various cultural tattooing methods from around the world. He is not just an artist, but a storyteller who believes each tattoo carries a profound narrative.",
        specialties: [
          "Traditional Tattoos",
          "Neo-Traditional Designs",
          "Cultural Tattoo Art",
          "Japanese Traditional Techniques",
          "Tribal Inspired Designs"
        ],
        awards: [
          "Master of Traditional Tattooing - World Tattoo Conference 2022",
          "Cultural Preservation in Art - Heritage Awards 2021"
        ],
        socialMedia: {
          instagram: "@mikechen_traditional",
          portfolio: "mikechenart.com",
          facebook: "Mike Chen Traditional Tattoo"
        }
      },
      {
        id: 7,
        image: team4,
        name: "Mike Chen",
        role: "Traditional Art Master",
        description: "Specializing in traditional and neo-traditional tattoo styles.",
        fullBio: "Mike Chen is a master of traditional tattoo art, bringing decades of cultural and artistic expertise to every piece. With a deep respect for tattoo history and traditional techniques, Mike has studied various cultural tattooing methods from around the world. He is not just an artist, but a storyteller who believes each tattoo carries a profound narrative.",
        specialties: [
          "Traditional Tattoos",
          "Neo-Traditional Designs",
          "Cultural Tattoo Art",
          "Japanese Traditional Techniques",
          "Tribal Inspired Designs"
        ],
        awards: [
          "Master of Traditional Tattooing - World Tattoo Conference 2022",
          "Cultural Preservation in Art - Heritage Awards 2021"
        ],
        socialMedia: {
          instagram: "@mikechen_traditional",
          portfolio: "mikechenart.com",
          facebook: "Mike Chen Traditional Tattoo"
        }
      }

  ];

  const TeamStats = () => (
    <div className="bg-[#527DE6] rounded-2xl p-8 mb-12">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">15+</h3>
          <p className="text-xl">Years of Combined Experience</p>
        </div>
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">500+</h3>
          <p className="text-xl">Successful Tattoo Projects</p>
        </div>
        <div className="text-white">
          <h3 className="text-4xl font-bold text-[#65D4B0] mb-2">10+</h3>
          <p className="text-xl">National Awards Won</p>
        </div>
      </div>
    </div>
  );


  const TeamMemberCard = ({ member, onSelect }) => (
    <div 
      className="bg-[#0D182E] rounded-xl p-6 space-y-4 transform transition-all duration-300 hover:scale-105 cursor-pointer"
      onClick={() => onSelect(member)}
    >
      <div className="h-64 overflow-hidden rounded-lg">
        <img 
          src={member.image} 
          alt={member.name} 
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      <div className="text-white text-center">
        <h3 className="text-2xl font-bold mb-2">{member.name}</h3>
        <p className="text-[#65D4B0] mb-2">{member.role}</p>
        <p className="text-gray-300 mb-4">{member.description}</p>
      </div>
    </div>
  );

  const MemberDetailModal = ({ member, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-70 z-50 flex items-center justify-center p-4 overflow-y-auto">
      <div className="bg-[#0D182E] rounded-2xl max-w-4xl w-full p-8 relative my-8">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-white text-3xl hover:text-[#65D4B0]"
        >
          &times;
        </button>
        <div className="flex flex-col md:flex-row items-start space-x-0 md:space-x-8">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <img 
              src={member.image} 
              alt={member.name} 
              className="w-full h-auto rounded-xl object-cover"
            />
          </div>
          <div className="w-full md:w-2/3 text-white">
            <h2 className="text-3xl font-bold mb-2">{member.name}</h2>
            <p className="text-[#65D4B0] text-xl mb-4">{member.role}</p>
            <p className="text-gray-300 mb-6">{member.fullBio}</p>
            
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Specialties</h3>
              <div className="flex flex-wrap gap-2">
                {member.specialties.map((specialty, index) => (
                  <span 
                    key={index} 
                    className="bg-[#65D4B0] bg-opacity-20 text-[#65D4B0] px-3 py-1 rounded-full text-sm"
                  >
                    {specialty}
                  </span>
                ))}
              </div>
            </div>
            
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-3">Awards & Recognitions</h3>
              <ul className="list-disc list-inside text-gray-300">
                {member.awards.map((award, index) => (
                  <li key={index}>{award}</li>
                ))}
              </ul>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-3">Connect</h3>
              <div className="flex space-x-4">
                {Object.entries(member.socialMedia).map(([platform, handle]) => (
                  <a 
                    key={platform}
                    href={platform === 'instagram' || platform === 'facebook' 
                      ? `https://${platform}.com/${handle.replace('@', '')}` 
                      : `https://${handle}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={`
                      ${platform === 'instagram' ? 'bg-[#B7BDCA] text-black' : 'bg-[#527DE6] text-white'}
                      px-4 py-2 rounded-lg hover:bg-opacity-80 transition-all
                    `}
                  >
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full bg-[#4D6F8F] p-3 space-y-8" style={{fontFamily: 'GeneralSansRegular'}}>
    <div className="w-full bg-[#527DE6] rounded-2xl mb-8 mt-16 flex justify-between items-center">
      <div className="max-w-full text-left p-6 sm:p-8">
        <h1 className="text-3xl font-[VarinoRegular] text-white">
          OUR Team
        </h1>
      </div>
        <div className="pr-6">
          <button 
            onClick={() => navigate('/BusinessDashboard/InviteEmployee')} 
            className="bg-[#B7BDCA] text-[#0D182E] px-6 py-2 rounded-lg hover:bg-opacity-80 transition-all"
          >
            Invite Team Memeber
          </button>
        </div>
      </div>

      <TeamStats />

      <div className="w-full bg-[#0D182E] py-12 px-8 rounded-2xl border-8 border-[#B7BDCA]">
        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl text-white font-bold mb-8 text-center">
            Our Talented Artists
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {teamMembersData.map((member) => (
              <TeamMemberCard 
                key={member.id} 
                member={member} 
                onSelect={setSelectedMember} 
              />
            ))}
          </div>
        </div>
      </div>

      {selectedMember && (
        <MemberDetailModal 
          member={selectedMember} 
          onClose={() => setSelectedMember(null)} 
        />
      )}
    </div>
  );
};

export default TeamPage;