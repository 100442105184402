import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import secimage from "./assets/images/worldchart.png"
import maleImage from "./assets/images/maleimage.png";
import femaleImage from "./assets/images/femaleimage.png";
import { TrendingUp, Users, DollarSign, Star, ShoppingCart, Activity, Clock, Eye, Bookmark, Share, File ,Zap} from 'lucide-react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend, RadialBar, RadialBarChart,
   AreaChart, Area, XAxis, YAxis, CartesianGrid, } from 'recharts';


   const generateData = () => {
    const data = [];
    const startDate = new Date('2012-01-01');
    const endDate = new Date('2013-02-27');
    
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 3)) {
      const baseValue = 20 + (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 7);
      const reviewRandomFactor = Math.random() * 2 - 1;
      const referralRandomFactor = Math.random() * 2 - 1;
      data.push({
        date: date.getTime(),
        reviews: parseFloat((baseValue + reviewRandomFactor).toFixed(2)),
        referrals: parseFloat((baseValue * 0.7 + referralRandomFactor).toFixed(2)) // Referrals slightly lower than reviews
      });
    }
    return data;
  };
  
  const sampleData = generateData();

  
const donutData = [
  { name: 'Male', value: 60 },
  { name: 'Female', value: 30 },
  { name: 'Others', value: 10 }
];

const donutDatanew = [
  { name: 'Spent', value: 75 },
  { name: 'Revenue', value: 25 }
];

const COLORS = ['#65D4B0', '#DC3DF7', '#527DE6'];


const performanceData = [
  { name: '12 Months', value: 75, reviews: 1200 },
  { name: '30 Days', value: 85, reviews: 350 },
  { name: '7 Days', value: 65, reviews: 80 },
];
const StatCard = ({ icon: Icon, title, value, description }) => (
  <div className="bg-[#0D182E] p-6 rounded-lg">
    <div className="flex items-center gap-4 mb-2">
      <div className="p-3 bg-transparent border-2 rounded-full">
        <Icon className="w-6 h-6 text-white" />
      </div>
      <h3 className="text-lg font-['GeneralSansSemibold'] text-slate-100">{title}</h3>
    </div>
    <p className="text-3xl font-['GeneralSansMedium'] text-[#DC3DF7] mb-1">{value}</p>
  </div>
);


const BusinessAnalytics = () => {
  const [currentPerformance, setCurrentPerformance] = useState(performanceData[0]);
   
  const [timeRange, setTimeRange] = useState('1Y');
  
  const filterData = (range) => {
    const now = new Date('2013-02-27').getTime();
    let startDate;
    
    switch(range) {
      case '1 Week':
        startDate = new Date('2013-02-20').getTime();
        break;
      case '1 Month':
        startDate = new Date('2013-01-27').getTime();
        break;
      case '1 Year':
        startDate = new Date('2012-02-27').getTime();
        break;
      default:
        startDate = new Date('2012-02-27').getTime();
    }
    
    return sampleData.filter(item => item.date >= startDate && item.date <= now);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  const formatTooltipDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <div className="min-h-screen pt-20 px-4 sm:px-6 lg:px-8 mb-10">
      <div className="w-full bg-[#527DE6] rounded-2xl mb-8">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            BUSINESS ANALYTICS
          </h1>
        </div>
      </div>

      <div className="w-full space-y-6 mt-10">
          {/* Time Series Chart */}
          <div className="w-95 p-6 m-5 rounded-lg bg-[#0D182E]">
      <h2 className="text-2xl font-['GeneralSansSemibold'] text-slate-100 mb-4">Reviews and Referrals</h2>
      <div className="h-px w-full bg-slate-700 mb-4" />
      
      <div className="mb-4 flex gap-2">
        {['1 Year', '1 Month', '1 Week'].map((range) => (
          <button
            key={range}
            onClick={() => setTimeRange(range)}
            className={`px-3 py-1 rounded font-['GeneralSansMedium'] ${
              timeRange === range
                ? 'bg-emerald-400 text-slate-900'
                : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
            }`}
          >
            {range}
          </button>
        ))}
      </div>
      
      <div className="h-[400px]">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={filterData(timeRange)}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorReviews" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#65D4B0" stopOpacity={0.8}/>
                <stop offset="95.55%" stopColor="#65D4B0" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="colorReferrals" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#DC3DF7" stopOpacity={0.8}/>
                <stop offset="95.55%" stopColor="#DC3DF7" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid 
              horizontal={true}
              vertical={false}
              stroke="#1E2A44"
            />
            <XAxis
              dataKey="date"
              tickFormatter={formatDate}
              type="number"
              domain={['dataMin', 'dataMax']}
              stroke="#4B5563"
              tick={{ fontFamily: 'GeneralSansRegular' }}
            />
            <YAxis 
              stroke="#4B5563"
              tick={{ fontFamily: 'GeneralSansRegular' }}
            />
            <Tooltip
              labelFormatter={formatTooltipDate}
              formatter={(value, name) => [`${value}`, name]}
              contentStyle={{ 
                background: '#1E2A44', 
                border: 'none', 
                borderRadius: '4px',
                fontFamily: 'GeneralSansRegular'
              }}
              labelStyle={{ color: '#9CA3AF' }}
            />
            <Area
              type="monotone"
              dataKey="reviews"
              stroke="#65D4B0"
              fillOpacity={1}
              fill="url(#colorReviews)"
            />
            <Area
              type="monotone"
              dataKey="referrals"
              stroke="#DC3DF7"
              fillOpacity={1}
              fill="url(#colorReferrals)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>

          {/* First Donut Chart */}
          <div className="w-full p-6 rounded-lg bg-[#0D182E]">
            <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4">Finances</h2>
            <div className="h-px w-full bg-slate-700 mb-4" />
            
            <div className="h-[400px] relative">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={donutDatanew}
                    innerRadius="60%"
                    outerRadius="80%"
                    paddingAngle={0}
                    dataKey="value"
                  >
                    {donutDatanew.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [`${value}%`, '']}
                    contentStyle={{ 
                      background: '#1E2A44', 
                      border: 'none', 
                      borderRadius: '4px',
                      fontFamily: 'GeneralSansRegular'
                    }}
                    itemStyle={{ color: '#65D4B0' }}
                  />
                  <Legend 
                    verticalAlign="bottom"
                    formatter={(value, entry) => (
                      <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Revenue</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$10000</div>
                <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Withdraw</div>
                <div className="text-3xl font-['GeneralSansBold'] text-slate-100">$1000</div>
              </div>
            </div>
          </div>
          
          {/* Stats Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6 bg-[#65D4B0] p-5 rounded-2xl">
            <StatCard 
              icon={Bookmark}
              title="Reviews"
              value="85%"
              description="Increase in monthly active users"
            />
            <StatCard 
              icon={Users}
              title="Refferals"
              value="2,345"
              description="Users active in the last 30 days"
            />
            <StatCard 
              icon={DollarSign}
              title="Revenue"
              value="$12,345"
              description="Total revenue this month"
            />
            <StatCard 
              icon={Eye}
              title="Page Visits"
              value="4.8"
              description="Average rating from users"
            />
            <StatCard 
              icon={ShoppingCart}
              title="Whishlist"
              value="1,234"
              description="Total orders this month"
            />
            <StatCard 
              icon={Clock}
              title="Time on App"
              value="78%"
              description="User engagement rate"
            />
            <StatCard 
              icon={Share}
              title="Shares"
              value="58"
              description="User engagement rate"
            />
            <StatCard 
              icon={File}
              title="Expenses"
              value="$1000"
              description="User engagement rate"
            />
            <StatCard 
  icon={Zap}
  title="Conversions"
  value="42%"
  description="Conversion rate from leads to customers"
/>
          </div>



        </div>
         {/* Business Analytics Container */}
      <div className="max-w-full bg-[#0D182E] rounded-lg shadow-md p-6 sm:p-8 mt-10">
        <h3 className="text-xl font-['GeneralSansMedium'] text-white mb-2">
          Top Town/Cities
        </h3>
        <div className="flex flex-col items-center">
          {/* Centered Image */}
          <div className="mb-8 w-full max-w-md">
            <img 
              src={secimage} 
              alt="Business Analytics" 
              className="w-full h-auto"
            />
          </div>

          {/* Progress Bars Container */}
          <div className="w-full space-y-6">
            {/* Progress Bar 1 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Melbourne
                </h3>
                <span className="text-white text-base">75%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '75%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>

            {/* Progress Bar 2 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Sydney
                </h3>
                <span className="text-white text-base">65%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '65%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>

            {/* Progress Bar 3 */}
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-['GeneralSansMedium'] text-white mb-2">
                  Brisbane
                </h3>
                <span className="text-white text-base">85%</span>
              </div>
              <div className="w-full bg-white rounded-full h-2 dark:bg-white">
                <div 
                  className="h-2 rounded-full" 
                  style={{ 
                    width: '85%', 
                    backgroundColor: '#DC3DF7' 
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full p-6 rounded-lg bg-[#0D182E] mt-10">
        <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4">Audience</h2>
        <div className="h-px w-full bg-slate-700 mb-4" />
        <div className="flex flex-col sm:flex-row justify-center mb-6 space-y-4 sm:space-y-0 sm:space-x-8">
          <div className="flex flex-col items-center w-full sm:w-1/4">
            <img 
              src={maleImage} 
              alt="Male" 
              className="w-24 h-24 object-contain"
            />
            <span className="text-white font-['GeneralSansMedium'] mt-2">Male  320</span>
          </div>
          <div className="flex flex-col items-center w-full sm:w-1/4">
            <img 
              src={femaleImage} 
              alt="Female"
              className="w-24 h-24 object-contain"
            />
            <span className="text-white font-['GeneralSansMedium'] mt-2">Female  215</span>
          </div>
        </div>
        
        <div className="h-[400px] relative">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={donutData}
                innerRadius="60%"
                outerRadius="80%"
                paddingAngle={0}
                dataKey="value"
              >
                {donutData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip 
                formatter={(value) => [`${value}%`, '']}
                contentStyle={{ 
                  background: '#1E2A44', 
                  border: 'none', 
                  borderRadius: '4px',
                  fontFamily: 'GeneralSansRegular'
                }}
                itemStyle={{ color: '#65D4B0' }}
              />
              <Legend 
                verticalAlign="bottom"
                formatter={(value, entry) => (
                  <span className="text-slate-300 font-['GeneralSansRegular']">{value}</span>
                )}
              />
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <div className="text-xl text-slate-400 font-['GeneralSansRegular']">Others</div>
            <div className="text-3xl font-['GeneralSansBold'] text-slate-100">50</div>
          </div>
        </div>
      </div>

      <div className="w-full p-6 rounded-lg bg-[#0D182E] mt-10 relative">
        <h2 className="text-2xl font-['GeneralSansBold'] text-slate-100 mb-4">Page Visits</h2>
        <div className="h-px w-full bg-slate-700 mb-4" />
        
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-3/4 h-[400px] relative mb-6  lg:mb-0">
            <ResponsiveContainer width="100%" height="100%">
              <RadialBarChart 
                innerRadius="70%" 
                outerRadius="90%" 
                data={[currentPerformance]} 
                startAngle={180} 
                endAngle={0}
              >
                <RadialBar 
                  minAngle={15} 
                  background 
                  clockWise 
                  dataKey="value" 
                  cornerRadius={14}
                  fill="#DC3DF7"
                  outerRadius="40%"
                />
                
                <RadialBar 
                  minAngle={15} 
                  background 
                  clockWise 
                  dataKey="value" 
                  cornerRadius={14}
                  fill="#3DE9DC"
                />
              </RadialBarChart>
            </ResponsiveContainer>
            
            {/* Custom Needle */}
            <div 
              className="absolute top-36 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1 h-28 bg-white origin-bottom"
              style={{
                transform: `translate(-50%, -50%) rotate(calc(180deg - (180deg * ${currentPerformance.value} / 100)))`,
                boxShadow: '0 0 10px rgba(255,255,255,0.3)'
              }}
            >
              <div 
                className="absolute bottom-0 left-1/2 -translate-x-1/2 w-4 h-4 bg-white border-2 border-[#DC3DF7] rounded-full"
                style={{
                }}
              ></div>
            </div>
            
            {/* Gauge Labels */}
            <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
              <div className="text-3xl font-['GeneralSansBold'] text-slate-100">{currentPerformance.value}%</div>
              <div className="text-xl text-slate-400 font-['GeneralSansRegular']">{currentPerformance.name}</div>
            </div>
          </div>
          
          {/* Performance Tabs */}
          <div className="w-full lg:w-1/4 lg:pl-4 flex flex-col space-y-2">
            {performanceData.map((perf) => (
              <button
                key={perf.name}
                onClick={() => setCurrentPerformance(perf)}
                className={`w-full p-3 text-left rounded-lg transition-all duration-300 flex justify-between items-center shadow-md 
                  ${currentPerformance.name === perf.name 
                    ? 'bg-gradient-to-r from-[#3DE9DC] to-[#3DE9DC]/80 text-[white] font-bold' 
                    : 'bg-[#1E2A44] text-slate-300 hover:bg-[#2C3E5A] hover:shadow-xl'
                }`}
              >
                <span className="text-base font-['GeneralSansMedium']">{perf.name}</span>
                <span className="text-sm font-['GeneralSansBold'] bg-[#0D182E]/50 px-2 py-1 rounded-full">{perf.reviews}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessAnalytics;