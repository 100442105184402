import React from 'react';
import { Mail, MapPin, Building2, Briefcase, ArrowLeft, ArrowRight } from 'lucide-react';
import paypal from "./assets/images/paypal-icon.png";
import profile from "./assets/images/profile.png";
import product from "./assets/images/imagestatic1.png";
import product2 from "./assets/images/imagestatic2.png";
import logo from "./assets/images/loogfinal.png";
import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
  const navigate = useNavigate();
  const userData = {
    email: "john.doe@example.com",
    fullName: "John Doe",
    businessName: "Acme Solutions",
    address: "123 Business Street, Sydney NSW 2000",
    abnNumber: "12 345 678 901",
    category: "Technology Services",
    paypalEmail: "business@acme.com",
    employees: [
      // You can modify this array to test empty scenarios
      // { id: 1, name: "Sarah Johnson", email: "sarah.j@acme.com", image: profile },
      // { id: 2, name: "Mike Smith", email: "mike.s@acme.com", image: profile },
      // { id: 3, name: "Emma Davis", email: "emma.d@acme.com", image: profile },
    ],
    products: [
      // You can modify this array to test empty scenarios
      // { 
      //   id: 1, 
      //   name: "Product 1", 
      //   image: product,
      //   price: "$99.99",
      //   description: "High-quality product with amazing features"
      // },
    ],
    gallery: [
      { id: 1, image: product2, title: "Office Space" },
      { id: 2, image: product2, title: "Team Meeting" },
      { id: 3, image: product2, title: "Product Launch" },
      { id: 4, image: product2, title: "Customer Service" },
      { id: 5, image: product2, title: "Innovation Lab" },
    ]
  };

  return (
    <div 
      className="w-full  flex flex-col items-center justify-center px-4"
      style={{
        background: 'radial-gradient(862.98% 189.02% at 92.82% 100%, #182B53 3.09%, #4D6F8F 39.35%, #DB005F 100%)'
      }}
    >
      <div className="text-center max-w-xl mb-8 mt-5">
        <div className="space-y-8 font-['GeneralSansRegular']">
          <img src={logo} width={150} className="mx-auto mb-6" alt="Review Pay Logo" />
          <div className="relative">
            <h1 className="text-white text-4xl font-['VerminViva'] mb-4">
              Review pay
            </h1>
            <h6
              className="absolute right-0 -bottom-2 text-white font-semibold "
              style={{ 
                fontFamily: 'Lastica', 
                transform: 'translateY(40%)',
                textShadow: '0 2px 4px rgba(0,0,0,0.3)',
                fontSize:8,
              }}
            >
              WHAT DO YOU THINK?
            </h6>
          </div>
          </div>
      </div>

      {/* Review and Confirm Box */}
      <div className="backdrop-blur-md bg-white/10 rounded-xl p-8 w-full max-w-4xl border border-white/20 shadow-2xl">
        <h2 className="text-white text-2xl font-['GeneralSansBold'] mb-6 text-center">
          Review And Confirm
        </h2>

        <div className="space-y-6">
          {/* Business Profile Section */}
          <div className="bg-white/5 border border-white/10 rounded-lg p-6">
            <h3 className="text-white text-xl font-['GeneralSansBold'] mb-4">
              Business Profile
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Left Column */}
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Mail className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300  font-['GeneralSansRegular']">Email</p>
                    <p className="text-white font-['GeneralSansRegular']">{userData.email}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <Building2 className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm  font-['GeneralSansRegular'] text-gray-300">Business Name</p>
                    <p className="text-white  font-['GeneralSansRegular']">{userData.businessName}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <MapPin className="w-5 h-5 text-white" />
                  <div>
                    <p className="text-sm text-gray-300  font-['GeneralSansRegular']">Address</p>
                    <p className="text-white  font-['GeneralSansRegular']">{userData.address}</p>
                  </div>
                </div>
              </div>
              
              {/* Right Column */}
              <div className="space-y-4">
                <div className="flex items-center space-x-3">
                  <Briefcase className="w-5 h-5 text-white " />
                  <div>
                    <p className="text-sm text-gray-300  font-['GeneralSansRegular']">ABN Number</p>
                    <p className="text-white  font-['GeneralSansRegular']">{userData.abnNumber}</p>
                  </div>
                </div>
                
                <div className="flex items-center space-x-3">
                  <Briefcase className="w-5 h-5 text-white  font-['GeneralSansRegular']" />
                  <div>
                    <p className="text-sm text-gray-300  font-['GeneralSansRegular']">Category</p>
                    <p className="text-white  font-['GeneralSansRegular']">{userData.category}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PayPal Section */}
          <div className="bg-white/5 border border-white/10 rounded-lg p-6">
            <div className="flex items-center space-x-4">
              <img src={paypal} alt="PayPal" className="h-12 w-16 object-contain" />
              <div>
                <p className="text-sm text-gray-300  font-['GeneralSansRegular']">PayPal Email</p>
                <p className="text-white  font-['GeneralSansRegular']">{userData.paypalEmail}</p>
              </div>
            </div>
          </div>

          {/* Conditional Employees Section */}
          {userData.employees.length > 0 && (
            <div className="bg-white/5 border border-white/10 rounded-lg p-6">
              <h3 className="text-white text-xl font-['GeneralSansBold'] mb-4">
                Employees
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {userData.employees.map(employee => (
                  <div key={employee.id} className="flex items-center space-x-4">
                    <div className="w-16 h-16 rotate-45 overflow-hidden bg-[#527DE6] rounded-xl">
                      <img 
                        src={employee.image} 
                        alt={employee.name}
                        className="-rotate-45 scale-150"
                        width={40}
                        height={60}
                      />
                    </div>
                    <div>
                      <p className="font-['GeneralSansMedium'] text-white">{employee.name}</p>
                      <p className="text-sm text-gray-300">{employee.email}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Conditional Products Section */}
          {userData.products.length > 0 && (
            <div className="bg-white/5 border border-white/10 rounded-lg p-6">
              <h3 className="text-white text-xl font-['GeneralSansBold'] mb-4">
                Products
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
                {userData.products.map(product => (
                  <div key={product.id} className="group relative">
                    <div className="relative overflow-hidden rounded-xl">
                      <img 
                        src={product.image} 
                        alt={product.name}
                        className="w-full h-40 object-cover rounded-xl transition-transform duration-300 group-hover:scale-110"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                          <p className="font-medium">{product.price}</p>
                          <p className="text-sm truncate">{product.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-white/10 rounded-lg shadow px-3 py-2 mt-2">
                      <p className="text-sm text-white text-center font-medium">{product.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Gallery Section */}
          <div className="bg-white/5 border border-white/10 rounded-lg p-6">
            <h3 className="text-white text-xl font-['GeneralSansBold'] mb-4">
              Gallery
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-4">
              {userData.gallery.map(item => (
                <div key={item.id} className="group relative overflow-hidden rounded-xl">
                  <img 
                    src={item.image} 
                    alt={item.title}
                    className="w-full h-40 object-cover rounded-xl transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                      <p className="font-medium  font-['GeneralSansMedium']">{item.title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-6 pt-4 border-t border-white/20">
          <button
            type="button"
            className="flex items-center px-6 py-3 border-2 border-white/30 text-white rounded-lg 
            font-['GeneralSansMedium'] hover:bg-white/10 transition-all duration-300"
            onClick={() => navigate("/BusinessVerification")}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </button>
          <button
            type="button"
            className="flex items-center px-6 py-3 bg-gradient-to-r from-pink-500 to-purple-600 
            text-white rounded-lg font-['GeneralSansBold'] hover:opacity-90 
            transition-all duration-300 shadow-lg hover:shadow-xl hover:scale-[1.02]"
            onClick={() => navigate("/BusinessDashboard")}
          >
            Continue
            <ArrowRight className="w-4 h-4 ml-2" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;