import React, { useState } from 'react';
import authImage from "./assets/images/authimage.png";

const CantScanQRCode = () => {
  const [copiedCode, setCopiedCode] = useState(false);
  const authCode = "6HKHMDC6CKGYKFBB";

  const handleCopyCode = () => {
    navigator.clipboard.writeText(authCode)
      .then(() => {
        setCopiedCode(true);
        setTimeout(() => setCopiedCode(false), 2000);
      });
  };

  return (
    <div className="min-h-screen py-10 flex flex-col items-center p-4 pt-32">
      <div className="w-full max-w-md text-center">
        <h1 className="text-4xl font-[GeneralSansSemibold] text-white mb-8">
          Can't scan the QR code?
        </h1>

        <div className="mb-8">
          <img 
            src={authImage} 
            alt="Authentication Guide" 
            className="w-52 h-52 mx-auto "
          />
        </div>

        <div 
          className="mb-6 py-4 px-6 bg-[#B7BDCA] rounded-xl flex items-center justify-center"
        >
          <span className="text-black text-2xl font-[GeneralSansMedium] tracking-wider">
            {authCode}
          </span>
        </div>

        <p className="text-white text-sm mb-6 font-[GeneralSansRegular]">
          If you can't scan the QR code with your camera, enter the following code into the authentication app to link it to your X account.
        </p>

        <button 
          onClick={handleCopyCode}
          className="w-full bg-[#0D182E] text-white px-6 py-3 rounded-xl transition-colors flex items-center justify-center font-[GeneralSansMedium]"
        >
          {copiedCode ? 'Copied!' : 'Copy Code'}
        </button>
      </div>
    </div>
  );
};

export default CantScanQRCode;