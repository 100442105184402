import React, { useState } from 'react';
import video1 from "./assets/images/reviewvideo.mp4";
import logo1 from "./assets/images/avatar2.png";
import logo2 from "./assets/images/logofinal2.png"
import { Upload } from 'lucide-react';
const ReviewSection = () => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  
  // Separate states for each satisfaction rating
  const [recommendRating, setRecommendRating] = useState(0);
  const [navigationRating, setNavigationRating] = useState(0);
  const [customerServiceRating, setCustomerServiceRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
    // Image pick handler (mock function)
    const pickImage = () => {
        alert('Image picker would be implemented here');
      };
  
  const [comment, setComment] = useState('');

  const questions = [
    {
      id: 1,
      question: 'What other features should we have in our app?',
      options: ['Built-in Review Form Generator', 'Built-in Review Form Generator', 'Built-in Review Form Generator', 'Built-in Review Form Generator']
    },
    {
      id: 2,
      question: 'Choose The best You Like',
      options: ['Built-in Review Form Generator', 'Built-in Review Form Generator', 'Built-in Review Form Generator', 'Built-in Review Form Generator']
    }
  ];

  return (
    <div className="w-full p-8 space-y-8" style={{fontFamily: 'GeneralSansRegular' }}>
      {/* Video Container with white border */}

      <div className="w-full bg-[#527DE6] rounded-2xl mb-8 mt-16">
        <div className="max-w-full text-left p-6 sm:p-8">
          <h1 className="text-3xl font-[VarinoRegular] text-white">
            POST REVIEW
          </h1>
        </div>
      </div>
      <div className="border-4 border-white rounded-xl overflow-hidden">
        <div className="relative w-full h-96">
          <video 
            className="w-full h-full object-cover"
            autoPlay 
            muted 
            loop
          >
            <source src={video1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
          {/* Logo and Text Container */}
          <div className="absolute bottom-8 left-8 flex items-center space-x-4">
            <img 
              src={logo1} 
              alt="Company Logo" 
              className="h-16 w-auto"
            />
            
            <div className="text-white w-44">
              <h2 className="text-2xl font-bold">Crown Casino Melborne</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Logo Rating Section */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How would you rate your overall satisfaction with our service?
        </h3>
        <div className="flex space-x-6 justify-center">
          {[1, 2, 3, 4, 5].map((rating) => (
            <button
              key={rating}
              onClick={() => setSelectedRating(rating)}
              className="w-14 h-14 relative transition-all"
            >
              <img
                src={logo2}
                alt={`Rating ${rating}`}
                className="w-full h-full object-contain transition-opacity"
                style={{ opacity: selectedRating >= rating ? 1 : 0.3 }}
              />
            </button>
          ))}
        </div>
      </div>

      {/* MCQ Section */}
      {questions.map((q) => (
        <div key={q.id} className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
          <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>{q.question}</h3>
          <div className="space-y-4 ">
            {q.options.map((option, index) => (
              <button
                key={index}
                onClick={() => setSelectedOptions(prev => ({...prev, [q.id]: index}))}
                className={`w-full p-4 rounded-lg border-2 flex justify-between items-center bg-[#B7BDCA]  ${
                  selectedOptions[q.id] === index 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#B7BDCA] hover:border-[#DC3DF7]'
                }`}
              >
                <span className="text-[#0D182E]">{option}</span>
                <div className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                  selectedOptions[q.id] === index 
                    ? 'border-[#DC3DF7] bg-[#DC3DF7]' 
                    : 'border-[#0D182E]'
                }`}>
                  {selectedOptions[q.id] === index && (
                    <div className="w-full p-2.5 bg-[#DC3DF7] rounded-full border-white border-2" />
                  )}
                </div>
              </button>
            ))}
          </div>
        </div>
      ))}

      {/* Satisfaction Rating Sections with separate states */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How likely are you to recommend our product/service to a friend or colleague?
        </h3>
        <div className="flex justify-between mb-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              onClick={() => setRecommendRating(rating)}
              className={`w-8 h-8 rounded flex items-center justify-center ${
                recommendRating >= rating 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-white'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-300">
          <span>Not at all Likely</span>
          <span>Very Likely</span>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How easy was it to navigate our website/app?
        </h3>
        <div className="flex justify-between mb-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              onClick={() => setNavigationRating(rating)}
              className={`w-8 h-8 rounded flex items-center justify-center ${
                navigationRating >= rating 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-white'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-300">
          <span>Not at all Likely</span>
          <span>Very Likely</span>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How was your experience with our customer service team?
        </h3>
        <div className="flex justify-between mb-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              onClick={() => setCustomerServiceRating(rating)}
              className={`w-8 h-8 rounded flex items-center justify-center ${
                customerServiceRating >= rating 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-white'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-300">
          <span>Not at all Likely</span>
          <span>Very Likely</span>
        </div>
      </div>

      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          How would you rate the quality of our product/service?
        </h3>
        <div className="flex justify-between mb-3">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((rating) => (
            <button
              key={rating}
              onClick={() => setQualityRating(rating)}
              className={`w-8 h-8 rounded flex items-center justify-center ${
                qualityRating >= rating 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-700 text-white'
              }`}
            >
              {rating}
            </button>
          ))}
        </div>
        <div className="flex justify-between text-sm text-gray-300">
          <span>Not at all Likely</span>
          <span>Very Likely</span>
        </div>
      </div>

           {/* New Additional Comments Section */}
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-lg text-white mb-6" style={{ fontFamily: 'GeneralSansSemibold' }}>
          Additional Comments
        </h3>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Please share any additional feedback you have about our product or service..."
          className="w-full h-32 p-4 bg-[#1A2747] text-white rounded-lg border border-gray-700 focus:outline-none focus:border-blue-500 resize-none"
          style={{ fontFamily: 'GeneralSansRegular' }}
        />
      </div>
             
      <div className="bg-[#0D182E] rounded-xl p-8 shadow-md border border-gray-700">
        <h3 className="text-white text-lg font-bold mb-6 font-['GeneralSansMedium']">Any Attachements To Show ?</h3>
        <div 
          onClick={pickImage}
          className="border border-[#E2E8F0] rounded-2xl h-44 flex flex-col justify-center items-center cursor-pointer"
        >
          <div className="mb-4">
            <Upload className="w-12 h-12 text-white" />
          </div>
          <p className="text-white text-base font-['GeneralSansMedium'] mb-1">
            Click to upload <span className="text-[#959DB0] text-sm">or drag and drop</span>
          </p>
          <p className="text-[#959DB0] text-sm">SVG, PNG, JPG or GIF (max. 800x400px)</p>
        </div>
      </div>

      {/* Submit Button */}
      <div className="text-center pt-4">
        <button 
          className="bg-[#0D182E] text-white font-[GenralSansRegular] py-3 px-8 rounded-lg transition-colors duration-300"
          style={{ fontFamily: 'GeneralSansMedium' }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default ReviewSection;