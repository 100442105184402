import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./SignIn";
import ForgotPassword from "./ForgotPassword";
import SavePassword from "./SavePassword";
import ChooseRole from "./ChooseRole";
import CreateAccountB from "./CreateAccount1";
import CreateAccountU from "./CreateAccount2";
import Bd from "./BusinessDeatils";
import Payment1 from "./PaymentBusiness";
import InviteEmploye from "./InviteEmployee";
import AddProducts from "./AddProducts";
import Review1 from "./BusinessReviewandConfirm";
import BusinessDashboard from "./BusinessDashboard";
import UserDash from "./UserDashboard";
import ProfilePage from "./ProfilePage";
import BusinessDetail from "./BusinessDetail";
import ComingSoonPage from "./CommingSoon";
import UserHome from "./UserHome";
import { TermsAndConditions } from "./TermsandConditions";
import QRCodePage from "./Qrcode";
import WalletPage from "./Wallet";
import NotificationScreen from "./Notifications";
import HelpScreen from "./Help";
import FeedbackScreen from "./Feedback";
import FAQScreen from "./FAQ";
import SettingsScreen from "./Settings";
import WelcomePage from "./Welcome";
import SplashScreen from "./Splashscreen";
import AnalyticsChart from "./UserAnalytics";
import ReviewSection from "./PostReview";
import TwoFactorAuthSettings from "./2fasettings";
import SignIn1 from "./SignIn1";
import AuthQr from "./Authqr";
import CantScanQRCode from "./CantScanqr";
import TextMessageAuth from "./AuthTextMessage";
import SecurityCodeVerification from "./EnterSecurityCode";
import ChangePasswordScreen from "./ChangePassword";
import NotFound from "./NotFound"; 
import UserDetailsStep from "./UserDetails";
import UserReviewConfirm from "./UserReviewandConfirm";
import BusinessProfileVerificationForm from "./BusinessVerificationFeilds";
import BusinessHome from "./BusinessHome";
import BTermsAndConditions  from "./BusinessTermsandConditions";
import BLegalPolicy from "./BusinessLegalPolicy";
import Customer from "./Customer"
import ReviewHistory from "./ReviewHistory";
import Referrals from "./Referrals";
import BusinessSendLink from "./SendReviewLink";
import IntegrationsPage from "./Integrations";
import BPrivacyPolicy from "./BusinessPrivacyPolicy";
import BusinessProducts from "./BusinessOurProducts";
import Team from "./BusinessOurTeam"
import EmailSignature from "./RefferalEmailSignature";
import EmailSignatureReview from "./ReviewEmail";
import EmailSignatureInvite from "./InviteEmail";
import EmailSignatureInvite2 from "./InviteEmail2";
import GaugeChart from "./BusinessAnaltics"
import InvoiceGenerator from "./Invoice";
import SendLinkScreen from "./SendLink";
import CreateFormBusiness from "./BuisnessCreateForm";
import BusinessMediaUploadStep from "./UploadBusinessMedia";
import InvoiceHistoryComponent from "./InvoiceHistory";
import ProfessionalDetailPage from "./ProfessionalDetails";
import BusinessSettingsScreen from "./BusinessSettings";
import SettingsBusinessRegistrationStep from "./BSBusinessDetails";
import SettingsBusinessVerificationForm from "./BSBusinessVerification";
import SettingsUserDetailsStep from "./USUserDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/signin" element={<SignIn1 />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/SavePassword" element={<SavePassword />} />
        <Route path="/ChooseRole" element={<ChooseRole />} />
        <Route path="/CreateAccountB" element={<CreateAccountB />} />
        <Route path="/CreateAccountU" element={<CreateAccountU />} />
        <Route path="/BusinessDetails" element={<Bd />} />
        <Route path="/AddProducts" element={<AddProducts />} />
        <Route path="/BusinessReviewandConfirm" element={<Review1 />} />
        <Route path="/UserReviewandConfirm" element={<UserReviewConfirm />} />
        <Route path="/BusinessDashboard" element={<BusinessDashboard />} />
        <Route path="/BusinessVerification" element={<BusinessProfileVerificationForm />} />
        <Route path="/CommingSoon" element={<ComingSoonPage />} />
        <Route path="/Welcome" element={<WelcomePage />} />
        <Route path="/UserDetails" element={<UserDetailsStep />} />
        <Route path="/" element={<SplashScreen />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/EmailSignature1" element={<EmailSignature />} />
        <Route path="/EmailSignature2" element={<EmailSignatureReview />} />
        <Route path="/EmailSignature3" element={<EmailSignatureInvite />} />
        <Route path="/EmailSignature4" element={<EmailSignatureInvite2 />} />
        <Route path="/Invoice" element={<InvoiceGenerator />} />
        <Route path="/UploadMedia" element={<BusinessMediaUploadStep />} />

        <Route path="/BusinessDashboard" element={<BusinessDashboard />} >
        <Route index element={<BusinessHome/>} />
        <Route path="QRCode" element={<QRCodePage />} />
        <Route path="TermsandConditions" element={<BTermsAndConditions />} />
        <Route path="LegalPolicy" element={<BLegalPolicy />} />
        <Route path="Customer" element={<Customer/>} />
        <Route path="Customer/ReviewHistory" element={<ReviewHistory/>} />
        <Route path="Customer/ReviewHistory/Referrals" element={<Referrals/>} />
        <Route path="BusinessSendLink" element={<BusinessSendLink/>} />
        <Route path="Integrations" element={<IntegrationsPage/>} />
        <Route path="settings" element={<BusinessSettingsScreen />} />
        <Route path="settings/2fasettings" element={<TwoFactorAuthSettings />} />
        <Route path="settings/ChangePassword" element={<ChangePasswordScreen />} />
        <Route path="settings/BusinessDetails" element={<SettingsBusinessRegistrationStep />} />
        <Route path="settings/BusinessVerification" element={<SettingsBusinessVerificationForm />} />
        <Route path="settings/2fasettings/Authqr" element={<AuthQr />} />
        <Route path="settings/2fasettings/TextAuth" element={<TextMessageAuth />} />
        <Route path="settings/2fasettings/TextAuth/EnterSecurityCode" element={<SecurityCodeVerification />} />
        <Route path="settings/2fasettings/Authqr/CantScanqr" element={<CantScanQRCode />} />
        <Route path="notifications" element={<NotificationScreen />} />
        <Route path="AddProducts" element={<AddProducts />} />
        <Route path="wallet" element={<WalletPage />} />
        <Route path="PrivacyPolicy" element ={<BPrivacyPolicy/>} />
        <Route path="help" element={<HelpScreen />} />
        <Route path="feedback" element={<FeedbackScreen />} />
        <Route path="InviteEmployee" element={<InviteEmploye />} />
        <Route path="BusinessAnalytics" element={<GaugeChart />} />
        <Route path="SendLink" element={<SendLinkScreen />} />
        <Route path="CreateFormBusiness" element={<CreateFormBusiness />} />
        <Route path="InvoiceHistory" element={<InvoiceHistoryComponent />} />
        <Route path="faqs" element={<FAQScreen />} />

        <Route path="BusinessDetail" element={<BusinessDetail />} />
          <Route path="BusinessDetail/team" element={<Team/>} />
          <Route path="BusinessDetail/products" element={<BusinessProducts/>} />
        </Route>

        <Route path="/UserDashboard" element={<UserDash />}>
          <Route index element={<UserHome />} />
          <Route path="TermsandConditions" element={<TermsAndConditions />} />
          <Route path="InvoiceHistory" element={<InvoiceHistoryComponent />} />
          <Route path="QRCode" element={<QRCodePage />} />
          <Route path="wallet" element={<WalletPage />} />
          <Route path="notifications" element={<NotificationScreen />} />
          <Route path="help" element={<HelpScreen />} />
          <Route path="feedback" element={<FeedbackScreen />} />
          <Route path="faqs" element={<FAQScreen />} />
          <Route path="settings" element={<SettingsScreen />} />
          <Route path="settings/2fasettings" element={<TwoFactorAuthSettings />} />
          <Route path="settings/ChangePassword" element={<ChangePasswordScreen />} />
          <Route path="settings/UserDetail" element={<SettingsUserDetailsStep />} />
          <Route path="PaymentBusiness" element={<Payment1 />} />
          <Route path="UserAnalytics" element={<AnalyticsChart />} />
          <Route path="PostReview" element={<ReviewSection />} />
          <Route path="settings/2fasettings/Authqr" element={<AuthQr />} />
          <Route path="settings/2fasettings/TextAuth" element={<TextMessageAuth />} />
          <Route path="settings/2fasettings/TextAuth/EnterSecurityCode" element={<SecurityCodeVerification />} />
          <Route path="settings/2fasettings/Authqr/CantScanqr" element={<CantScanQRCode />} />
          <Route path="ProfilePage" element={<ProfilePage />} />
          <Route path="PrivacyPolicy" element ={<BPrivacyPolicy/>} />
          <Route path="ProfessionalDetails" element ={<ProfessionalDetailPage/>} />

        </Route>

      </Routes>
    </Router>
  );
}

export default App;